import { generateFilterOptions, getRulesFiltersConfig } from '../utils/filterHelper';
import { FiltersAndTable } from '../FiltersAndTable';

interface IProps {
  ruleSummaries: object;
  mastId: string;
  excludeFilters?: string[];
}

export const RulesTab = ({ ruleSummaries, mastId, excludeFilters = [] }: IProps) => {
  const filtersConfig = getRulesFiltersConfig(ruleSummaries, excludeFilters);

  const filterOptions = generateFilterOptions(filtersConfig, ruleSummaries);

  return <FiltersAndTable filtersConfig={filtersConfig} options={filterOptions} mastId={mastId} data={ruleSummaries} />;
};
