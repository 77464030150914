import { RulesTable } from '@kw/quokka-ui';
import { useMemo } from 'react';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { fetchService } from '../../../utils/fetchService';

export const GuardianRulesTable = function GuardianRulesTable({ data, mastId }: { data: object; mastId: string }) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');

  const getEvidencesCallback = async (mastUuid, ruleGuid, page, pageSize) => {
    const results = await fetchService(
      `/application/results/${mastUuid}/evidence?ruleGuid=${ruleGuid}&page=${page}&pageSize=${pageSize}`,
      accessToken,
    );
    return { data: results };
  };

  // memoize the RulesTable component to prevent re-rendering when the parent component re-renders because of search bar updates
  // especially since the RulesTable component is a heavy component in terms of rendering time
  return useMemo(
    () => <RulesTable ruleSummaries={data} uuid={mastId} getEvidencesCallback={getEvidencesCallback} />,
    [data, mastId, accessToken],
  );
};
