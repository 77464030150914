import { QTooltip } from '@kw/quokka-ui';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';

interface IProps {
  analyzedVersion: string;
  installedVersion: string;
}

export const SubstituteVersion = ({ analyzedVersion, installedVersion }: IProps) => {
  return analyzedVersion && installedVersion !== analyzedVersion ? (
    <QTooltip title={`Substitute version applied`}>
      <SyncAltOutlinedIcon />
    </QTooltip>
  ) : (
    <></>
  );
};
