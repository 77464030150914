import { AppMetadataPanel } from '@kw/quokka-ui';
import _ from 'lodash';
import fileSize from 'filesize';

export const MetadataPanel = ({ isOpen, setIsOpen, appDetails }) => {
  const appInfo = {
    name: appDetails?.appName,
    version: appDetails?.version,
    versionCode: appDetails?.versionCode,
    minSdk: appDetails?.minOsVersion,
    targetSdk: null,
    fileSize: appDetails?.size ? fileSize(appDetails?.size) : '',
    submissionDate: null, // TODO: Add submission date to QScout Backend API.
    submissionTag: null, // TODO: Add submission tag/category to QScout Backend API.
    sha1: null,
    md5: null,
  };

  const marketInfo = {
    category: appDetails?.categories?.map(category => _.capitalize(category)).join(', ') || '',
    lastUpdated: appDetails?.releaseDate,
    download: appDetails?.downloads,
    privacyPolicy: appDetails?.privacyPolicyUrl,
    developer: appDetails?.developerName,
    developerEmail: null,
    developerUrl: appDetails?.developerUrl,
  };

  return <AppMetadataPanel isOpen={isOpen} setIsOpen={setIsOpen} appInfo={appInfo} marketInfo={marketInfo} certificateInfo={{}} />;
};
