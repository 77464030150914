import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { QTabContext, QTabList, QTab, QTabPanel } from '@kw/quokka-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { Integrations } from './Integrations';
import { OrganizationSettings } from './OrganizationSettings';
import { UserManagement } from './UserManagement';
import { LaunchDarklyFlags } from '../../launch-darkly';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';

function a11yProps(index: number) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}

export function Settings() {
  const [activeTab, setActiveTab] = useState<string>('0');

  const handleChange = (_event: ChangeEvent<HTMLButtonElement>, tab: string) => {
    setActiveTab(tab);
  };
  const flags: LaunchDarklyFlags['flags'] = useFlags();

  const canAccessMdm = verifyAuthPermission(AuthPermissions.SETTINGS_INTEGRATIONS_MDM) && flags.intuneIntegration;
  const canAccessOkta = verifyAuthPermission(AuthPermissions.SETTINGS_INTEGRATIONS_OKTA) && flags.oktaIntegration;
  const canAccessSupportEmail = verifyAuthPermission(AuthPermissions.SETTINGS_SUPPORT_EMAIL);
  const canAccessSupportEmailAndMdm = canAccessSupportEmail && canAccessMdm;
  const canAccessSupportEmailAndOkta = canAccessSupportEmail && canAccessOkta;
  const hasSupportEmailAndIntegrationsAccess = canAccessSupportEmailAndMdm || canAccessSupportEmailAndOkta;
  const hasSupportEmailAccessOnly = canAccessSupportEmail && !canAccessMdm && !canAccessOkta;
  const hasIntegrationsAccessOnly = canAccessMdm || canAccessOkta;

  return (
    <KwContainer>
      <BackgroundProgressBar />
      {hasSupportEmailAndIntegrationsAccess ? (
        <StyledTabContainer>
          <QTabContext value={activeTab}>
            <QTabList tabs="true" onChange={handleChange}>
              <StyledQTab label="Integrations" value="0" {...a11yProps(0)} />
              <StyledQTab label="Organization Settings" value="1" {...a11yProps(1)} />
              <StyledQTab label="Users" value="2" {...a11yProps(2)} />
            </QTabList>
            <StyledQTabPanel tabid="0" value="0" index={0}>
              <Integrations />
            </StyledQTabPanel>
            <StyledQTabPanel tabid="1" value="1" index={1}>
              <OrganizationSettings />
            </StyledQTabPanel>
            <StyledQTabPanel tabid="2" value="2" index={2}>
              <UserManagement />
            </StyledQTabPanel>
          </QTabContext>
        </StyledTabContainer>
      ) : hasIntegrationsAccessOnly ? (
        <StyledTabContainer>
          <QTabContext value={activeTab}>
            <QTabList tabs="true" onChange={handleChange}>
              <StyledQTab label="Integrations" value="0" {...a11yProps(0)} />
              <StyledQTab label="Users" value="1" {...a11yProps(1)} />
            </QTabList>
            <StyledQTabPanel tabid="0" value="0" index={0}>
              <Integrations />
            </StyledQTabPanel>
            <StyledQTabPanel tabid="1" value="1" index={1}>
              <UserManagement />
            </StyledQTabPanel>
          </QTabContext>
        </StyledTabContainer>
      ) : hasSupportEmailAccessOnly ? (
        <OrganizationSettings />
      ) : null}
    </KwContainer>
  );
}

const StyledQTab = styled(QTab)`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  &.Mui-selected {
    color: #000;
    font-weight: 700;
  }
  padding: 20px 40px;
`;

const StyledQTabPanel = styled(QTabPanel)`
  padding: 24px 0;
`;

const StyledTabContainer = styled(Box)`
  border-bottom: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;
