/* eslint-disable react/forbid-component-props */
import { useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { QTabContext, QTabList, QTab, QTabPanel, MaliciousScoreChart, LibrariesAndFrameworksChartCard } from '@kw/quokka-ui';
import { RulesChart } from '../charts/RulesChart';
import { PermissionsChart } from '../charts/PermissionsChart';
import { AggregateDataProps } from '../types/ReportTypes';
import { getMaliciousThreatLevel } from '../utils/dopplerUtil';
import { RulesTab } from './RulesTab';
import { LibrariesAndFrameworks } from './LibrariesAndFrameworks';
import { Permissions } from './Permissions';

export function OverviewTab({
  aggregateData,
  ruleSummaries,
  mastId,
  maliciousResult,
  os,
}: AggregateDataProps & { mastId: string } & { ruleSummaries: any[] } & { maliciousResult: object } & { os: string }) {
  const [activeTab, setActiveTab] = useState('rules');

  const handleTabChange = (event, newTabValue) => {
    setActiveTab(newTabValue);
  };

  const libraryRelatedRuleSummaries = useMemo(
    () => ruleSummaries?.filter(({ rule }) => rule.id.includes('loads-library')),
    [ruleSummaries],
  );

  const PERMISSION_RULE_IDS = ['profile/system/requests-privileged-permissions'];

  const permissionRelatedRuleSummaries = useMemo(
    () => ruleSummaries?.filter(({ rule }) => rule.tool === 'permoid' || PERMISSION_RULE_IDS.includes(rule.id)),
    [ruleSummaries],
  );
  const maliciousScore = getMaliciousThreatLevel(maliciousResult);
  const chartWidth = os !== 'android' ? '33%' : '25%';
  return (
    <Box>
      <Box display="flex" gap="16px">
        <Box sx={{ width: chartWidth }}>
          <RulesChart rulesSummary={aggregateData?.levelSummary} />
        </Box>
        <Box sx={{ width: chartWidth }}>
          <PermissionsChart permissionsSummary={aggregateData?.permissionSummary} />
        </Box>
        <Box sx={{ width: chartWidth }}>
          <LibrariesAndFrameworksChartCard
            systemLibraries={0}
            systemFrameworks={0}
            loadedLibrariesAndFrameworks={libraryRelatedRuleSummaries?.length ?? 0}
          />
        </Box>
        {os === 'android' && (
          <Box sx={{ width: chartWidth }}>
            <MaliciousScoreChart score={maliciousScore} />
          </Box>
        )}
      </Box>

      <QTabContext value={activeTab}>
        <QTabList
          onChange={handleTabChange}
          sx={{
            '.MuiTabs-indicator': {
              top: 0, // Moves the indicator to the top
              bottom: 'unset', // Removes default bottom positioning
            },
            '.MuiTab-root': {
              paddingTop: '16px',
              fontSize: '14px',
              fontWeight: '600',
              color: '#757575',
              minWidth: '226px',
            },
            '.Mui-selected': {
              backgroundColor: '#FAFAFA',
              color: '#000000',
              fontWeight: '800',
            },
            backgroundColor: '#EEEEEE',
            borderBottom: 'none',
            marginTop: '40px',
          }}
        >
          <QTab label="Rules" value="rules" />
          <QTab label="Permissions" value="permissions" />
          <QTab label="Libraries & Frameworks" value="libraries" />
        </QTabList>
        <QTabPanel value="rules" sx={{ padding: 0 }}>
          <RulesTab ruleSummaries={ruleSummaries} mastId={mastId} />
        </QTabPanel>
        <QTabPanel value="permissions">
          <Permissions ruleSummaries={permissionRelatedRuleSummaries} mastId={mastId} />
        </QTabPanel>
        <QTabPanel value="libraries" sx={{ padding: 0 }}>
          <LibrariesAndFrameworks ruleSummaries={libraryRelatedRuleSummaries} mastId={mastId} />
        </QTabPanel>
      </QTabContext>
    </Box>
  );
}
