import { QTabContext, QTabList, QTab, QTabPanel } from '@kw/quokka-ui';
import { useState, ChangeEvent, useMemo } from 'react';
import { OverviewTab } from './OverviewTab';
import { AggregateDataProps } from '../types/ReportTypes';
import { BehaviorsTab } from './BehaviorsTabs';
import { NetworkInfoTab } from './NetworkInfoTab';
import { MaliciousTab } from './MaliciousTab';

export function RootTabs({
  aggregateData,
  ruleSummaries,
  mastId,
  maliciousResult,
  os,
  appDetails
}: AggregateDataProps & { ruleSummaries: any[]; mastId: string; maliciousResult: any; os: string; appDetails: any; }) {

  function a11yProps(index: number) {
    return {
      id: `settings-tab-${index}`,
      'aria-controls': `settings-tabpanel-${index}`,
    };
  }

  const [activeTab, setActiveTab] = useState<string>('0');

  const handleChange = (_event: ChangeEvent<HTMLButtonElement>, tab: string) => {
    setActiveTab(tab);
  };

  const behavioralRuleSummaries = useMemo(() => {
    ruleSummaries?.sort((a, b) => b.result_level_numeric - a.result_level_numeric);

    return ruleSummaries?.filter(
      ruleSummary => ruleSummary?.rule?.tool === 'pulsar' && ruleSummary.rule?.properties?.category === 'behavioral',
    );
  }, [ruleSummaries]);

  return (
    <QTabContext value={activeTab}>
      <QTabList tabs="true" onChange={handleChange} variant="fullWidth">
        <QTab label="Overview" value="0" {...a11yProps(0)} />
        <QTab label="Network Info" value="1" {...a11yProps(1)} />
        <QTab label="Behaviors" value="2" {...a11yProps(2)} />
        {os === 'android' && <QTab label="Malicious Report" value="3" {...a11yProps(3)} />}
      </QTabList>
      <QTabPanel tabid="0" value="0" index={0}>
        <OverviewTab
          aggregateData={aggregateData}
          ruleSummaries={ruleSummaries}
          mastId={mastId}
          maliciousResult={maliciousResult}
          os={os}
        />
      </QTabPanel>
      <QTabPanel tabid="1" value="1" index={1}>
        <NetworkInfoTab appDetails={appDetails} />
      </QTabPanel>
      <QTabPanel tabid="2" value="2" index={2}>
        <BehaviorsTab ruleSummaries={behavioralRuleSummaries} mastId={mastId} />
      </QTabPanel>
      <QTabPanel tabid="3" value="3" index={3}>
        {os === 'android' && (
          <MaliciousTab ruleSummaries={ruleSummaries} mastId={mastId} maliciousResult={maliciousResult} aggregateData={aggregateData} />
        )}
      </QTabPanel>
    </QTabContext>
  );
}
