import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Chip } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { PolicyResult, PolicyViolationsModal, RiskIndicator } from './PolicyViolationsModal';

interface PolicyViolationsProps {
  orgPolicyResults?: PolicyResult[];
  riskIndicators?: RiskIndicator[];
}

export default function PolicyViolations({ orgPolicyResults = [], riskIndicators = [] }: PolicyViolationsProps) {
  const triggeredPolicies = orgPolicyResults.filter(p => p.didTrigger);

  const [accordionOpen, setAccordionOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<PolicyResult | null>(null);

  if (triggeredPolicies.length === 0) return null;

  const handleAccordionChange = (_: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionOpen(isExpanded);
  };

  const handlePolicyClick = (policy: PolicyResult) => {
    setSelectedPolicy(policy);
    setModalOpen(true);
  };

  const handleCloseDialog = () => {
    setModalOpen(false);
    setSelectedPolicy(null);
  };

  return (
    <StyledAccordion expanded={accordionOpen} onChange={handleAccordionChange} disableGutters square elevation={0}>
      <StyledAccordionSummary expandIcon={<ExpandMore />}>
        <RedTitle>Policy Violations</RedTitle>
        <ViolationCountChip label={triggeredPolicies.length} size="small" />
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        {triggeredPolicies.map(policy => (
          <StyledTypography key={policy.id} onClick={() => handlePolicyClick(policy)}>
            {policy.policyName}
          </StyledTypography>
        ))}
      </StyledAccordionDetails>

      <PolicyViolationsModal open={modalOpen} policy={selectedPolicy} onClose={handleCloseDialog} riskIndicatorsData={riskIndicators} />
    </StyledAccordion>
  );
}

const StyledAccordion = styled(Accordion)(() => ({
  border: 'none',
  boxShadow: 'none',
  '&::before': {
    display: 'none',
  },
  '&.MuiAccordion-root': {
    margin: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',

  '& .MuiAccordionSummary-content': {
    margin: 0,
    flexGrow: 0, // do not expand to fill
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  padding: theme.spacing(1, 2),
}));

const RedTitle = styled(Typography)(() => ({
  color: '#F44336',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
}));

const StyledTypography = styled(Typography)(() => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  width: '456px',
  border: '1px solid #6C6C6C',
  borderRadius: '16px',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '24px',
  paddingRight: '24px',
}));

const ViolationCountChip = styled(Chip)(() => ({
  marginLeft: '7px',
  marginRight: '7px',
  backgroundColor: '#F44336',
  color: 'white',
}));
