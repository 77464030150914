import { useMemo } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { QChip } from '@kw/quokka-ui';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';

export interface AssociatedRule {
  name: string;
  type: string;
  ruleId: string;
  didTrigger: boolean;
  appIorResultsMap: Record<string, string[] | string>;
}

export interface TriggeredAction {
  name: string;
  description: string;
}

export interface PolicyResult {
  id: string;
  didTrigger: boolean;
  policyName: string;
  associatedRules: AssociatedRule[];
  actionsTriggered: TriggeredAction[];
  policyDescription: string;
}

export interface RiskIndicator {
  id: string;
  key: string;
  isPrivacyConcern: boolean;
  isSecurityConcern: boolean;
  weight: number;
  adminTitle?: string;
  title?: string;
  description?: string;
}

interface PolicyViolationsModalProps extends DialogProps {
  open: boolean;
  policy: PolicyResult | null;
  onClose: () => void;
  riskIndicatorsData: RiskIndicator[];
}

export function PolicyViolationsModal({ open, policy, onClose, riskIndicatorsData, ...dialogProps }: PolicyViolationsModalProps) {
  const riskIndicatorMap = useMemo(() => {
    const map: Record<string, RiskIndicator> = {};
    if (!riskIndicatorsData) return map;

    riskIndicatorsData.forEach(indicator => {
      if (indicator.key) {
        map[indicator.key] = indicator;
      }
    });

    return map;
  }, [riskIndicatorsData]);

  if (!policy) return null;

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Policy Violation</DialogTitle>
      <Divider />

      <StyledDialogContent dividers>
        <Section>
          <LabelHeading>
            <img width={16} src={'/icons/shield-checked.svg'} alt="Policy" />
            Policy
          </LabelHeading>
          <Typography variant="body2">
            <UnderlinedSpan>{policy.policyName}</UnderlinedSpan>: {policy.policyDescription || 'No description provided.'}
          </Typography>
        </Section>

        {policy.associatedRules?.length
          ? policy.associatedRules.map(rule => {
              let riskIndicators = getRiskIndicators(rule, riskIndicatorMap);
              riskIndicators = riskIndicators.sort((a, b) => b.weight - a.weight);

              return (
                <Section key={rule.ruleId}>
                  <LabelHeading>
                    <img width={16} src={'/icons/clipboard.svg'} alt="Rule" />
                    <span>Rule</span>
                  </LabelHeading>
                  <Typography variant="body2">{rule.name}</Typography>

                  {riskIndicators.length > 0 && (
                    <>
                      <LabelSubHeading>Issues triggered:</LabelSubHeading>
                      {riskIndicators.map((riskIndicator, idx) => {
                        const category = getCategory(riskIndicator.weight);
                        const muiColor = getMuiColor(category);

                        return (
                          <IssueTriggeredText key={idx}>
                            <StyledWarningIcon color={muiColor} />
                            <span>{riskIndicator.adminTitle || riskIndicator.key}</span>
                            <StyledQChip
                              variant="primaryLight"
                              key={idx}
                              label={
                                riskIndicator.isSecurityConcern && riskIndicator.isPrivacyConcern
                                  ? 'Security and Privacy'
                                  : riskIndicator.isSecurityConcern
                                  ? 'Security'
                                  : 'Privacy'
                              }
                              size="small"
                            />
                          </IssueTriggeredText>
                        );
                      })}
                    </>
                  )}
                </Section>
              );
            })
          : null}
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Got it
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

function getRiskIndicators(rule: AssociatedRule, riskIndicatorMap: Record<string, RiskIndicator>): RiskIndicator[] {
  if (!rule.didTrigger || !rule.appIorResultsMap) {
    return [];
  }

  const allKeys = Object.values(rule.appIorResultsMap).flat();
  const uniqueKeys = Array.from(new Set(allKeys.filter(Boolean)));

  return uniqueKeys.map(key => riskIndicatorMap[key as string]).filter(Boolean);
}

const getCategory = (weight: number): string => {
  if (weight >= 75) {
    return 'High';
  }
  if (weight >= 35) {
    return 'Medium';
  }
  return 'Low';
};

function getMuiColor(category: string): 'error' | 'warning' | 'disabled' {
  switch (category) {
    case 'High':
      return 'error';
    case 'Medium':
      return 'warning';
    default:
      return 'disabled';
  }
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    backgroundColor: '#FAFAFA',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
    borderRadius: 4,
  },
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const Section = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4, // small gap between heading and text
}));

const LabelHeading = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 700,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 3,
}));

const LabelSubHeading = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 600,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 3,
}));

const IssueTriggeredText = styled(Typography)(() => ({
  fontSize: 14,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 3,
}));

const UnderlinedSpan = styled('span')(() => ({
  textDecoration: 'underline',
}));

const StyledQChip = styled(QChip)(() => ({
  fontSize: 14,
  marginLeft: 'auto',
  color: '#244C58',
  backgroundColor: '#DCE7E8',
  padding: '4px 16px'
}));

const StyledWarningIcon = styled(WarningIcon)`
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 17px;
  height: 17px;
`;
