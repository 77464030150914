import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { QContainer, QThemeProvider } from '@kw/quokka-ui';
import { useFetchService } from '../../../utils/fetchService';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { useOrganization } from '../../Settings/SettingsDropDownMenu';
import { NavigationContext } from '../../Navigation/NavigationProvider';
import { ActionsButtonHeader } from './ActionsButtonHeader';
import { RootTabs } from './tabs/RootTabs';
import { GuardianReportHeaderAndMetadata } from './GuardianReportHeaderAndMetadata';
import { aggregateResultsForSingleRule } from './utils/dopplerUtil';
import PolicyViolations from './PolicyViolations/PolicyViolations';

export default function SarifReport() {
  const location = useLocation();
  const { appId, appName, appVersion, os, mastId } = Object.fromEntries(new URLSearchParams(location.search));

  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { setAppName } = useContext(NavigationContext);

  const { orgData } = useOrganization(accessToken);
  // Do we still consider Denied Apps as a feature? Will it be available in all licenses?
  const showDeniedApps = orgData?.settings?.includes('denied-apps');

  setAppName(appName);

  // Fetch Rule Summaries
  const { data: ruleSummariesData, error: ruleSummariesErrors } = useFetchService(
    `/application/results/${mastId}/rule-summaries`,
    accessToken,
  );
  const isRuleSummariesLoading = !ruleSummariesData && !ruleSummariesErrors;
  const ruleSummaries = isRuleSummariesLoading ? null : transformRuleSummaries(ruleSummariesData);

  function transformRuleSummaries(data) {
    data.sort((a, b) => b.result_level_numeric - a.result_level_numeric);

    const fullRuleSummaries = data.map(ruleSummary => {
      const details = aggregateResultsForSingleRule(ruleSummary.rule, {}, ruleSummary.result_level_numeric);
      return {
        ...ruleSummary,
        details,
        level: details?.risk,
      };
    });

    return fullRuleSummaries;
  }

  // fetch malicious result
  const malwareRule = !isRuleSummariesLoading ? ruleSummaries?.find(ruleSummary => ruleSummary.rule?.id?.startsWith('malware/')) : {};
  const { data: maliciousData } = useFetchService(`/application/results/${mastId}/evidence?ruleGuid=${malwareRule?.guid}`, accessToken);
  const maliciousResult = maliciousData?.entries && maliciousData.entries.length > 0 ? maliciousData.entries[0].result : {};

  // Fetch Aggregate Data
  const { data: aggregateData, error: aggregateDataErrors } = useFetchService(`/application/results/${mastId}/aggregate-data`, accessToken);
  const isAggregateDataLoading = !aggregateData && !aggregateDataErrors;

  // Fetch Denied Apps
  const { data: deniedAppsResponse, error: deniedAppsErrors, mutate: deniedAppsMutate } = useFetchService('/list/denied-apps', accessToken);
  const isDeniedAppsLoading = !deniedAppsResponse && !deniedAppsErrors;
  const deniedApps = isDeniedAppsLoading ? null : deniedAppsResponse.deniedApps;

  // Fetch Trusted Apps
  const {
    data: trustedAppsResponse,
    error: trustedAppsErrors,
    mutate: trustedAppsMutate,
  } = useFetchService('/list/trusted-apps', accessToken);
  const isTrustedAppsLoading = !trustedAppsResponse && !trustedAppsErrors;
  const trustedApps = isTrustedAppsLoading ? null : trustedAppsResponse.trustedApps;

  // Fetch App Details
  const { data: appDetailsResponse, error: appDetailsErrors } = useFetchService(
    `/eval/applications/os/${os}/application/${appId}/version/${appVersion}`,
    accessToken,
  );

  const isAppDetailsLoading = !appDetailsResponse && !appDetailsErrors;
  const appDetails = appDetailsResponse || null;
  const iconUrl = appDetails?.current?.appDetails?.appIconBase64
    ? `data:image/png;base64,${appDetails.current.appDetails.appIconBase64}`
    : '';

  const triggeredPolicies = appDetails?.current?.appAnalysis?.result?.orgPolicyResults?.filter((p: any) => p.didTrigger) || [];
  const hasPolicyViolation = triggeredPolicies.length > 0;

  return (
    <QThemeProvider>
      <QContainer
        sx={{
          paddingTop: '16px',
          maxWidth: '100% !important',
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          height: '100%',
          overflow: 'auto',
        }}
      >
        {!isTrustedAppsLoading && !isDeniedAppsLoading && !isAppDetailsLoading && (
          <>
            <ActionsButtonHeader
              deniedApps={deniedApps}
              trustedApps={trustedApps}
              appDetails={appDetails}
              fetchDeniedApps={deniedAppsMutate}
              fetchTrustedApps={trustedAppsMutate}
              showDeniedApps={showDeniedApps}
            />
            <GuardianReportHeaderAndMetadata
              appName={appDetails?.current?.appDetails.appName}
              appId={appDetails?.current?.appDetails.appId}
              appVersion={
                appDetails?.current?.appDetails.version !== '0'
                  ? appDetails?.current?.appDetails.version
                  : appDetails?.current?.appDetails.analyzedVersion
              }
              os={appDetails?.current?.appDetails.os}
              iconUrl={iconUrl}
              appDetails={appDetails?.current}
              tag={
                hasPolicyViolation ? { label: 'Policy Violation', color: '#FF0000', iconSrc: '/icons/alert-triangle-red.svg' } : undefined
              }
            />
            <PolicyViolations
              orgPolicyResults={appDetails?.current?.appAnalysis?.result?.orgPolicyResults}
              riskIndicators={appDetails?.iors}
            />
          </>
        )}
        {!isRuleSummariesLoading && !isAggregateDataLoading && !isAppDetailsLoading && (
          <RootTabs
            aggregateData={aggregateData}
            ruleSummaries={ruleSummaries}
            mastId={mastId}
            maliciousResult={maliciousResult}
            os={os}
            appDetails={appDetails}
          />
        )}
      </QContainer>
    </QThemeProvider>
  );
}
