import { QTable, QTableBody, QTableCell, QTableRow, QSearchBar } from '@kw/quokka-ui';
import { useState, useMemo, useEffect } from 'react';
import { Box } from '@mui/system';

import { Filters } from './Filters';
import { getInitialFilters, filterByQuery as filterBySearchQuery } from './utils/filterHelper';
import { GuardianRulesTable } from './GuardianRulesTable';

interface IProps {
  filtersConfig: object;
  options: object;
  mastId: string;
  data: object;
}

export const FiltersAndTable = ({ filtersConfig, options, mastId, data }: IProps) => {
  const [filteredByDropDown, setFilteredByDropDown] = useState(data);
  const [filteredBySearchBox, setFilteredBySearchBox] = useState(data);
  const [query, setQuery] = useState('');

  // Get the initial filters based on the given filters config.
  // Basically, it returns an object with the keys of the filters and the values set to 'all'
  const initialFilters = useMemo(() => getInitialFilters(filtersConfig), [filtersConfig]);

  // Handlers for Drop-Down filters updates. The filters filter the original data
  // then invokes this handler. We set apply two setState calls so that we speed up the rendering time.
  const handleFilteredDataFromSelects = filteredData => {
    setFilteredByDropDown(filteredData);
    const newFilteredData = filterBySearchQuery(filteredData, query);
    setFilteredBySearchBox(newFilteredData);
  };

  // Handlers for SearchBar updates. The search bar invokes the handler with the updated query.
  const handleSearchQuery = e => {
    const value = e.target.value.toString();
    setQuery(value);
  };

  const removeSearchQuery = () => {
    setQuery('');
    setFilteredBySearchBox(filteredByDropDown);
  };

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      const value = e.target.value.toString();
      setQuery(value);
      const newFilteredData = filterBySearchQuery(filteredByDropDown, value);
      setFilteredBySearchBox(newFilteredData);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <QTable data-testid="rules-table">
        <QTableBody>
          <QTableRow hoverDisabled>
            <QTableCell sx={{ padding: '0px' }}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Filters
                  initialFilters={initialFilters}
                  filtersConfig={filtersConfig}
                  options={options}
                  onChange={handleFilteredDataFromSelects}
                  data={data}
                />
                <QSearchBar
                  onChange={handleSearchQuery}
                  searchValue={query}
                  onRemove={removeSearchQuery}
                  onKeyPress={e => handleKeyPress(e)}
                />
              </Box>
            </QTableCell>
          </QTableRow>
        </QTableBody>
      </QTable>
      <GuardianRulesTable data={filteredBySearchBox} mastId={mastId} />
    </Box>
  );
};
