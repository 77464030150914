import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  MaliciousScoreFactorsChart,
  ThreatTypeDistributionChartCard,
  ProfileCategoriesBarChart,
  QContainer,
  QCard,
  QCardHeader,
  QDivider,
  QCardContent,
} from '@kw/quokka-ui';

import { getMaliciousThreatLevel, getMaliciousThreatTypes } from '../utils/dopplerUtil';
import { PermissionsChart } from '../charts/PermissionsChart';
import { BehaviorsChartCard } from '../charts/BehaviorsChart';
import { RulesTab } from './RulesTab';
import { PermissionSummary } from '../types/ReportTypes';

interface AggregateData {
  permissionSummary?: PermissionSummary[];
}

interface IProps {
  ruleSummaries: any;
  mastId: string;
  maliciousResult: object;
  aggregateData: AggregateData;
}

export function MaliciousTab({ ruleSummaries, mastId, maliciousResult, aggregateData }: IProps) {
  const [maliciousRulesSummaries, setMaliciousRulesSummaries] = useState([]);
  const [filteredMaliciousBehaviors, setFilteredMaliciousBehaviors] = useState([]);
  const farmAppUuid = mastId;

  useEffect(() => {
    const maliciousRules = ruleSummaries.filter(
      ruleSummary =>
        (ruleSummary.rule?.tool === 'pulsar' || ruleSummary.rule?.tool === 'pulsar-ios' || ruleSummary.rule?.tool === 'permoid') &&
        ruleSummary.rule?.id?.startsWith('profile'),
    );
    setMaliciousRulesSummaries(maliciousRules);
    setFilteredMaliciousBehaviors(maliciousRules);
  }, []);

  const maliciousScore = getMaliciousThreatLevel(maliciousResult);
  const maliciousThreatTypes = getMaliciousThreatTypes(maliciousResult);

  return (
    <QContainer
      fluid
      sx={{
        maxWidth: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      <Box>
        <Box display="flex" sx={{ gap: '16px', pb: '16px' }}>
          <Box sx={{ width: '38%' }}>
            <MaliciousScoreFactorsChart score={maliciousScore} threatTypes={maliciousThreatTypes} />
          </Box>
          <Box sx={{ width: '37%' }}>
            <ThreatTypeDistributionChartCard threatTypes={maliciousThreatTypes} />
          </Box>
          <Box sx={{ width: '25%' }}>
            <PermissionsChart permissionsSummary={aggregateData?.permissionSummary} />
          </Box>
        </Box>
        <Box display="flex" sx={{ gap: '16px' }}>
          <Box sx={{ width: '75%' }}>
            <ProfileCategoriesBarChart results={maliciousRulesSummaries.map(behavior => behavior.details)} />
          </Box>
          <Box sx={{ width: '25%' }}>
            <BehaviorsChartCard results={maliciousRulesSummaries.map(behavior => behavior.details)} />
          </Box>
        </Box>
      </Box>
      <QCard
        sx={{
          padding: '0',
          marginBottom: '18px',
          boxShadow: 'none',
          borderRadius: '0',
        }}
      >
        <QCardHeader disableTypography title="Behaviors" />
        <QDivider />
        <QCardContent sx={{ padding: '0px' }}>
          <QDivider sx={{ borderColor: '#E0E0E0' }} />
          <RulesTab ruleSummaries={filteredMaliciousBehaviors} mastId={farmAppUuid} excludeFilters={['compliance']} />
        </QCardContent>
      </QCard>
    </QContainer>
  );
}
