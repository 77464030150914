import { List, ListItemButton, ListItemText, Popover } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { createQueryString } from '../../utils/createQueryString';
import { fetchService } from '../../utils/fetchService';
import { AddDeniedAppConfirmationModal } from './modals/AddDeniedAppConfirmationModal';
import { RemoveDeniedAppConfirmationModal } from './modals/RemoveDeniedAppConfirmationModal';
import { ActivityIndicator } from './ActivityIndicator';
import { AddTrustedAppConfirmationModal } from './modals/AddTrustedAppConfirmationModal';
import { RemoveTrustedAppConfirmationModal } from './modals/RemoveTrustedAppConfirmationModal';

interface IProps {
  deniedApps: { bundleId: string; os: string }[];
  trustedApps: { bundleId: string; os: string }[];
  applicationId: string;
  applicationVersion: string;
  os: string;
  name: string;
  isOpen: boolean;
  anchorEl: HTMLElement;
  handleClose: () => void;
  fetchDeniedApps: () => void;
  fetchTrustedApps: () => void;
  showDeniedApps: boolean;
  disableOptions?: boolean;
}

type Option = {
  text: string;
  disabled?: boolean;
};

export function ApplicationsPopover({
  deniedApps,
  trustedApps,
  applicationId,
  applicationVersion,
  os,
  name,
  isOpen,
  anchorEl,
  handleClose,
  fetchDeniedApps,
  fetchTrustedApps,
  showDeniedApps,
  disableOptions = false,
}: IProps) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [vendorName, setVendorName] = useState('');
  const [showAddDeniedAppConfirmationModal, setShowAddDeniedAppConfirmationModal] = useState(false);
  const [showRemoveDeniedAppConfirmationModal, setShowRemoveDeniedAppConfirmationModal] = useState(false);
  const [showAddTrustedAppConfirmationModal, setShowAddTrustedAppConfirmationModal] = useState(false);
  const [showRemoveTrustedAppConfirmationModal, setShowRemoveTrustedAppConfirmationModal] = useState(false);
  const [showActivityIndicator, setShowActivityIndicator] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isDenied = deniedApps.some(app => app.bundleId === applicationId && app.os === os);

  const options: Option[] = [];
  const isTrusted = trustedApps.some(app => app.bundleId === applicationId && app.os === os);

  if (disableOptions) {
    options.push({ text: 'Add to Trusted Apps', disabled: true });
    options.push({ text: 'Add to Denied Apps', disabled: true });
    options.push({ text: 'Generate PDF Report', disabled: true });
  } else {
    if (showDeniedApps) {
      if (!isDenied && !isTrusted) {
        options.push({ text: 'Add to Trusted Apps' });
        options.push({ text: 'Add to Denied Apps' });
      } else if (isDenied && !isTrusted) {
        options.push({ text: 'Add to Trusted Apps', disabled: true });
        options.push({ text: 'Remove from Denied Apps' });
      } else if (!isDenied && isTrusted) {
        options.push({ text: 'Remove from Trusted Apps' });
        options.push({ text: 'Add to Denied Apps', disabled: true });
      }
    } else if (!isTrusted) {
      options.push({ text: 'Add to Trusted Apps' });
    } else {
      options.push({ text: 'Remove from Trusted Apps' });
    }

    options.push({ text: 'Generate PDF Report' });
  }

  const handlePopoverClick = async (value: string) => {
    if (value === 'Generate PDF Report') {
      // enqueueSnackbar('PDF report requested', { variant: 'success' });
      const generatePDFReport = async () => {
        try {
          setShowActivityIndicator(true);
          const reportResponse = await fetchService(`/application/${os}/${applicationId}/${applicationVersion}/report`, accessToken);
          const blob = new Blob([reportResponse], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${applicationId}_${applicationVersion}_report.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          // enqueueSnackbar('PDF report provided', { variant: 'success' });
        } catch (error) {
          console.error('Error generating PDF report', error);
          enqueueSnackbar('Error generating PDF report', { variant: 'error' });
        } finally {
          setShowActivityIndicator(false);
        }
      };
      generatePDFReport();
    } else {
      const queryParamString = createQueryString({
        packageNames: [applicationId],
        os,
      });

      const response = await fetchService(`/pdm/app-catalog/packages?${queryParamString}`, accessToken);
      if (Array.isArray(response) && response.length > 0) {
        setVendorName(response[0].vendorName || '');
      } else {
        setVendorName('');
      }

      if (value === 'Add to Denied Apps') {
        setShowAddDeniedAppConfirmationModal(true);
      } else if (value === 'Remove from Denied Apps') {
        setShowRemoveDeniedAppConfirmationModal(true);
      } else if (value === 'Add to Trusted Apps') {
        setShowAddTrustedAppConfirmationModal(true);
      } else if (value === 'Remove from Trusted Apps') {
        setShowRemoveTrustedAppConfirmationModal(true);
      }
    }
    handleClose();
  };

  return (
    <>
      <StyledPopover
        id={applicationId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={e => e.stopPropagation()}
      >
        <StyledList>
          {options.map(option => (
            <ListItemButton
              onClick={() => {
                handlePopoverClick(option.text);
              }}
              key={option.text}
              disabled={option.disabled}
            >
              <ListItemText primary={option.text} />
            </ListItemButton>
          ))}
        </StyledList>
      </StyledPopover>
      <AddDeniedAppConfirmationModal
        showConfirmationModal={showAddDeniedAppConfirmationModal}
        setShowConfirmationModal={setShowAddDeniedAppConfirmationModal}
        selectedApp={{ applicationId, os, name, vendorName }}
        fetchDeniedApps={fetchDeniedApps}
      />
      <RemoveDeniedAppConfirmationModal
        showConfirmationModal={showRemoveDeniedAppConfirmationModal}
        setShowConfirmationModal={setShowRemoveDeniedAppConfirmationModal}
        selectedApp={{ applicationId, os, name, vendorName }}
        fetchDeniedApps={fetchDeniedApps}
      />
      <AddTrustedAppConfirmationModal
        showConfirmationModal={showAddTrustedAppConfirmationModal}
        setShowConfirmationModal={setShowAddTrustedAppConfirmationModal}
        selectedApp={{ applicationId, os, name, vendorName }}
        fetchTrustedApps={fetchTrustedApps}
      />
      <RemoveTrustedAppConfirmationModal
        showConfirmationModal={showRemoveTrustedAppConfirmationModal}
        setShowConfirmationModal={setShowRemoveTrustedAppConfirmationModal}
        selectedApp={{ applicationId, os, name, vendorName }}
        fetchTrustedApps={fetchTrustedApps}
      />

      <ActivityIndicator active={showActivityIndicator} message="Generating PDF Report" subtitle="This may take up to 1 minute" />
    </>
  );
}

const StyledList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
`;

const rulesPopoverStyles = css`
  & div.popover-text {
    max-width: 300px;
    font-size: 0.875rem;
    padding: 12px;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const StyledPopover = styled(Popover)`
  ${rulesPopoverStyles}
`;
