import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { QButton } from '@kw/quokka-ui';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { BackgroundProgressBar } from '../../BackgroundProgressBar/BackgroundProgressBar';
import { ApplicationsPopover } from '../ApplicationsPopover';
import { Link } from '../../../Link';

interface IProps {
  deniedApps: { bundleId: string; os: string }[];
  trustedApps: { bundleId: string; os: string }[];
  appDetails: any;
  fetchDeniedApps: () => void;
  fetchTrustedApps: () => void;
  showDeniedApps: boolean;
}

export function ActionsButtonHeader({ deniedApps, trustedApps, appDetails, fetchDeniedApps, fetchTrustedApps, showDeniedApps }: IProps) {
  const [actionButtonAnchorEl, setActionButtonAnchorEl] = useState(null);
  const [actionPopoverIsOpen, setActionPopoverIsOpen] = useState(false);

  const handleActionButtonPopoverClick = (event: { currentTarget: HTMLButtonElement | HTMLAnchorElement } = null) => {
    setActionButtonAnchorEl(event.currentTarget);
    setActionPopoverIsOpen(true);
  };

  const handlePopoverClose = () => {
    setActionButtonAnchorEl(null);
    setActionPopoverIsOpen(false);
  };

  return (
    <>
      <BackgroundProgressBar />
      <StyledButtonContainer>
        <StyledBackButton href="/applications">
          <StyledArrowBack />
          <StyledBackText>Back to App List</StyledBackText>
        </StyledBackButton>
        <StyledKwButton endIcon={<KeyboardArrowDownIcon />} variant="filled" onClick={event => handleActionButtonPopoverClick(event)}>
          Actions
        </StyledKwButton>
      </StyledButtonContainer>
      <ApplicationsPopover
        deniedApps={deniedApps}
        trustedApps={trustedApps}
        applicationId={appDetails?.current?.appDetails?.appId}
        applicationVersion={appDetails?.current?.appDetails?.analyzedVersion}
        os={appDetails?.current?.appDetails?.os}
        name={appDetails?.current?.appDetails?.appName}
        isOpen={actionPopoverIsOpen}
        anchorEl={actionButtonAnchorEl}
        handleClose={handlePopoverClose}
        fetchDeniedApps={fetchDeniedApps}
        fetchTrustedApps={fetchTrustedApps}
        showDeniedApps={showDeniedApps}
      />
    </>
  );
}

/** styles */

const StyledButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledKwButton = styled(QButton)`
  margin-left: auto;
  min-width: 110px;
`;

const StyledBackButton = styled(Link)`
  display: flex;
  height: 30px;
  padding-left: 18px;
  padding-right: 22px;
  column-gap: 11px;
  width: 177px;
  border-radius: 4px;
  margin-bottom: 7px;

  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.palette.greyOverride[800]};

  &:hover {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
`;

const StyledBackText = styled('div')`
  font-size: 13px;
  line-height: 13px;
`;

const StyledArrowBack = styled(ArrowBackIcon)`
  width: 16px;
`;
