import { UAParser } from 'ua-parser-js';
import { styled } from '@mui/material/styles';

export default function OktaTroubleshooting() {
  const parser = new UAParser();
  const {
    os: { name: deviceOs },
  } = parser.getResult();

  const IosAppStoreLogo = () => {
    return (
      <a href="https://apps.apple.com/app/q-scout/id1634815152" target="_blank" rel="noreferrer">
        <StyledImage src="/img/ios-app-store.png" alt="ios-app-store-logo" width={128} height={40} />
      </a>
    );
  };

  const AndroidAppStoreLogo = () => {
    return (
      <a href="https://play.google.com/store/apps/details?id=com.kryptowire.matador&hl=en_US&gl=US" target="_blank" rel="noreferrer">
        <StyledImage src="/img/google-play-store.png" alt="google-play-store-logo" width={135} height={40} />
      </a>
    );
  };

  return (
    <>
      <StyledLogo>
        <img src="/logo/Quokka_Logo_RGB_wht.svg" alt="quokka-logo" width={128} height={56} />
      </StyledLogo>
      <StyledSection>
        <StyledPageHeader>Q-Scout/Okta Verify Troubleshooting Guide</StyledPageHeader>
        <p>
          This article details the primary steps to troubleshoot Okta Verify security requirements issues. Your Okta Admin has adopted
          Q-Scout as a means of Device Management. You must install Q-Scout on this device and use a Managed version of the Okta Verify app
          to access corporate resources.
        </p>
        <StyledSectionHeader>Checklist to resolve Okta Verify issues</StyledSectionHeader>
        <StyledList>
          <li>
            <b>Have you installed the Q-Scout app?</b>
            <StyledListItemContent>
              Make sure you have downloaded and installed the Q-Scout app on this device. You cannot install the Managed version of Okta
              Verify without it.
            </StyledListItemContent>
          </li>
          <StyledImageWrapper>
            {deviceOs === 'iOS' ? (
              <IosAppStoreLogo />
            ) : deviceOs === 'Android' ? (
              <AndroidAppStoreLogo />
            ) : (
              <>
                <IosAppStoreLogo />
                <AndroidAppStoreLogo />
              </>
            )}
          </StyledImageWrapper>
          <li>
            <b>Have you joined your company&apos;s Q-Scout Organization?</b>
            <StyledListItemContent>
              With the Q-Scout app installed, go to Settings in the Q-Scout app. You should see your Organization&apos;s name at the top of
              the page. Contact your Q-Scout admin to request an invite if you&apos;re not joined to the Org.
            </StyledListItemContent>
          </li>
          <StyledImage src="/img/device-joined-org-V2.png" alt="device-joined-q-scout-org" width={159} height={118} />
          <li>
            <b>Do you have the Managed version of Okta Verify from Q-Scout?</b>
            <StyledListItemContent>
              If you successfully joined your company&apos;s deployment, you should see Okta Verify available as a Managed App.{' '}
              {deviceOs === 'Android'
                ? 'Logout of and uninstall any version of Okta Verify installed from the Google Play Store and install the managed version, which has special configuration required to access your corporate resources.'
                : 'The managed version you get from Q-Scout has a special configuration access your corporate resources.'}
            </StyledListItemContent>
          </li>
          <StyledImage src="/img/device-managed-okta-verify-V2.png" alt="device-have-managed-okta-verify" width={173} height={128} />
          <li>
            <b>Is your device in compliance?</b>
            <StyledListItemContent>
              Your access may have been denied because of a violation of a Q-Scout Policy. Open the Q-Scout app and check the Organization
              Policy panel at the top of the Home page. If you are out of compliance with a policy preventing your access, read the
              suggestions provided to bring your device back into compliance.
            </StyledListItemContent>
          </li>
          <StyledImage src="/img/device-not-compliant-V2.png" alt="device-in-compliance" width={173} height={139} />
          <p>If you require further assistance, please contact your Okta Admin.</p>
        </StyledList>
      </StyledSection>
    </>
  );
}

const StyledLogo = styled('div')`
  background-color: #3b3e40;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledPageHeader = styled('h2')`
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
`;

const StyledSection = styled('section')`
  margin: 18px;
`;

const StyledSectionHeader = styled('h4')`
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
`;

const StyledList = styled('ol')`
  padding-inline-start: 0;
  margin: 18px;
`;

const StyledListItemContent = styled('p')`
  margin-top: 0;
`;

const StyledImage = styled('img')`
  display: block;
  margin: 16px auto;
`;

const StyledImageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  img {
    margin: 16px 8px;
  }
`;
