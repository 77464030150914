import _ from 'lodash';

// Functions for transforming the rules, aggregate data from the SARIF data

export const getSeverity = score => {
  let severity = 'low';
  if (score >= 7) {
    severity = 'high';
  } else if (score >= 4) {
    severity = 'moderate';
  }

  return severity;
};

export const getResults = data => {
  const results = [];
  if (data.runs && data.runs.length > 0) {
    for (let i = 0; i < data.runs.length; i++) {
      const runsResults = _.get(data.runs[i], 'results', []);
      results.push(...runsResults);
    }
  }
  return results;
};

export const getRules = data => {
  const rules = [];
  if (data.runs && data.runs.length > 0) {
    for (let i = 0; i < data.runs.length; i++) {
      const runsRules = _.get(data.runs[i], 'tool.driver.rules', []).map(
        rule => ({
          ...rule,
          driverName: _.get(data.runs[i], 'tool.driver.name', ''),
        }),
      );
      rules.push(...runsRules);
    }
  }
  return rules;
};

const findRule = (results, ruleId) => {
  const rules = getRules(results);

  return rules.find(rule => rule.id === ruleId);
};

export const getRuleName = (results, ruleId) => {
  const selectedRule = findRule(results, ruleId);

  return selectedRule?.shortDescription?.text ?? '';
};

export const getCWEs = (results, ruleId) => {
  const selectedRule = findRule(results, ruleId);

  return (
    selectedRule?.properties?.tags?.filter(tag => tag.includes('CWE')) ?? []
  );
};

const levelOrder = {
  informational: 0, // what we show for issue level 'none'
  note: 1,
  warning: 2,
  error: 3,
};

const sortFindings = (a, b) => {
  const levelComparison = levelOrder[b.risk] - levelOrder[a.risk];

  if (levelComparison !== 0) {
    return levelComparison;
  }

  // If risk levels are equal, compare by ruleId
  return a.ruleId.localeCompare(b.ruleId);
};
const processRiskLevel = (kind, level) => {
  // kind defaults to fail if it doesn't exist
  if (!kind || _.lowerCase(kind) === 'fail') {
    // level defaults to warning if it's not set when kind is fail
    if (!level) {
      return 'warning';
    }

    return _.lowerCase(level) === 'none' ? 'informational' : _.lowerCase(level);
  }
  return 'informational';
};

const normalizeId = id => id.toLowerCase().trim();

export const aggregateResultsForSingleRule = (
  rule,
  optionalRuleFindingsMap = {},
  processedRiskLevelNumeric = null,
) => {
  const { niap = [], gdpr = [] } = rule.properties?.compliance || {};
  const processedRiskLevel = Object.keys(levelOrder)[processedRiskLevelNumeric];

  const owasp =
    rule.properties?.compliance?.['owasp-2016'] ||
    rule.properties?.compliance?.owasp ||
    [];

  const normalizedRuleId = normalizeId(rule.id);

  const ruleFindingsMap = optionalRuleFindingsMap;
  if (_.isEmpty(ruleFindingsMap)) {
    ruleFindingsMap[normalizedRuleId] = [
      { ruleId: normalizedRuleId, level: rule.defaultConfiguration?.level },
    ];
  }

  // Find matching rule ids, considering rule.id could be a prefix
  const matchingRuleIds = Object.keys(ruleFindingsMap).filter(resultRuleId =>
    resultRuleId.startsWith(normalizedRuleId),
  );

  let aggregatedResults;

  if (matchingRuleIds.length > 0) {
    // Aggregate all results for the matching rule IDs
    const allEvidences = matchingRuleIds.flatMap(
      matchingRuleId => ruleFindingsMap[matchingRuleId],
    );

    // Determine risk level based on the first result's kind and level of severity
    const riskLevel =
      processedRiskLevel ??
      processRiskLevel(allEvidences[0].kind, allEvidences[0].level);
    // Determine evidence and score from each result
    const confidence = allEvidences.map(
      evidence => evidence.properties?.confidence || 'none',
    );
    const score = allEvidences.map(evidence => evidence.properties?.score || 0);

    aggregatedResults = {
      name: [
        rule.shortDescription?.text ??
          rule.name ??
          // first sentence of fullDescription should be descriptive enough in case both previous values are not set
          // https://docs.oasis-open.org/sarif/sarif/v2.1.0/os/sarif-v2.1.0-os.html#_Toc34317846
          rule.fullDescription?.text?.split('.')[0],
      ],
      driverName: rule.driverName || rule.tool,
      description: rule.fullDescription?.text ?? ' ',
      niap,
      owasp,
      gdpr,
      ...rule.properties,
      risk: riskLevel,
      evidences: allEvidences,
      ruleId: rule.id,
      confidence,
      score,
    };
  }

  return aggregatedResults;
};

// This function will merge all evidences of all findings by the same rule id in the result object
// and also merge all the properties we need to render from the rules found in the tools object
// Result will be { rasp-not-detected: [{}], [{}]}
export const aggregateResultsFromRuleId = (rules, results) => {
  // create an object that has all ruleIds {ruleId1: [], ruleId2: []}
  const ruleFindingsMap = {};
  if (!_.isEmpty(results)) {
    results.forEach(result => {
      const { ruleId } = result;
      if (!Object.prototype.hasOwnProperty.call(ruleFindingsMap, ruleId)) {
        ruleFindingsMap[ruleId] = [];
      }
      ruleFindingsMap[ruleId].push(result);
    });
  }

  const detailResultFromRules = [];

  rules.forEach(rule => {
    const aggregatedResults = aggregateResultsForSingleRule(
      rule,
      ruleFindingsMap,
    );
    if (aggregatedResults) {
      detailResultFromRules.push(
        aggregateResultsForSingleRule(rule, ruleFindingsMap),
      );
    }
  });
  detailResultFromRules.sort(sortFindings);

  return detailResultFromRules;
};

export const getMaliciousThreatLevel = data => {
  return data?.properties?.threatLevel || -1;
};

export const getMaliciousThreatTypes = data => {
  return data?.properties?.threatTypes ?? {};
};
