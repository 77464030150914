import React, { useState } from 'react';
import { QSelect } from '@kw/quokka-ui';
import { applyFilters } from './utils/filterHelper';

export const Filters = ({ initialFilters, filtersConfig, options, onChange, data }) => {
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  // Event Handler for individual filter selection changes
  const handleFilterChange = (key, value) => {
    const newSelectedFilters = {
      ...selectedFilters,
      [key]: value,
    };

    setSelectedFilters(newSelectedFilters);
    const filteredData = applyFilters(filtersConfig, data, newSelectedFilters);
    onChange(filteredData);
  };

  const handleSelectChange = (filterKey, event) => {
    const selected = event.target.value;
    // Find the selected option based on value
    const option = options[filterKey].find(opt => opt.value === selected) || {
      label: 'All',
      value: 'all',
    };
    return handleFilterChange(filterKey, option);
  };

  return (
    <div className="filters-container" style={{ display: 'flex', gap: '8px' }}>
      {filtersConfig.map(filter => (
        <div key={filter.key} style={{ width: '200px' }}>
          <QSelect
            size="small"
            options={options[filter.key]}
            value={selectedFilters[filter.key]?.value}
            onChange={value => handleSelectChange(filter.key, value)}
            label={filter.label}
            formControlProps={{ sx: { width: '180px' } }}
            selectIconProps={{ fontSize: 'medium' }}
            selectProps={{
              'data-testid': 'select-filter',
            }}
          />
        </div>
      ))}
    </div>
  );
};
