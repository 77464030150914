import { styled } from '@mui/material/styles';
import { MouseEvent, useEffect, useState } from 'react';
import { Avatar, css, IconButton, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import DeniedIcon from '@mui/icons-material/GppBad';
import TrustedIcon from '@mui/icons-material/GppGood';
import { Android, Apple, CalendarToday, MoreVert as MoreVertIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
import { Link } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import { TableRowLink } from '../shared/TableRowLink';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { PageLoader } from '../../PageLoader';
import { fetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import CountriesContactedCellContent from './CountriesContactedCellContent';
import { ApplicationsPopover } from './ApplicationsPopover';
import { useOrganization } from '../Settings/SettingsDropDownMenu';
import { LaunchDarklyFlags } from '../../launch-darkly';
import { SubstituteVersion } from './SubstituteVersion';

type SortOrder = 'ASC' | 'DESC' | null;
interface IProps {
  apps: (ApplicationVersion & {
    applicationVersion: string;
    icon: string;
    importantFindings: boolean;
    management: string;
    policyViolations: boolean;
    policyIds: string[];
    riskIndicatorCount: number;
    title: string;
    versions: { deviceCount: number; deviceIds: string[]; version: string }[];
  })[];
  total: number;
  appsDataError: boolean;
  isLoading: boolean;
  highRiskCountries: string[];
  highRiskCountriesError: boolean;
  searchQuery: string;
  setSearchQuery: React.Dispatch<string>;
  appNameOrder: SortOrder;
  toggleAppNameOrder: () => void;
  numberOfIorsOrder: SortOrder;
  toggleNumberOfIorsOrder: () => void;
  dateSubmittedOrder: SortOrder;
  toggleDateSubmittedOrder: () => void;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<number>;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ApplicationsTable({
  apps,
  total,
  appsDataError,
  isLoading,
  highRiskCountries,
  highRiskCountriesError,
  searchQuery,
  setSearchQuery,
  appNameOrder,
  toggleAppNameOrder,
  numberOfIorsOrder,
  toggleNumberOfIorsOrder,
  dateSubmittedOrder,
  toggleDateSubmittedOrder,
  page,
  pageSize,
  setPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: IProps) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { orgData, organizationError, isLoading: isLoadingOrg } = useOrganization(accessToken);
  const [popoverId, setPopoverId] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [deniedApps, setDeniedApps] = useState([]);
  const [trustedApps, setTrustedApps] = useState([]);
  const flags: LaunchDarklyFlags['flags'] = useFlags();

  const fetchDeniedApps = async () => {
    try {
      const response = await fetchService('/list/denied-apps', accessToken);
      setDeniedApps(response.deniedApps);
    } catch (error) {
      console.error('Error fetching denied apps', error);
    }
  };

  const fetchTrustedApps = async () => {
    try {
      const response = await fetchService('/list/trusted-apps', accessToken);
      setTrustedApps(response.trustedApps);
    } catch (error) {
      console.error('Error fetching trusted apps', error);
    }
  };

  // initialize dayjs with UTC plugin to handle local timezones
  dayjs.extend(utc);

  useEffect(() => {
    fetchDeniedApps();
    fetchTrustedApps();
  }, [accessToken]);

  const showDeniedApps = orgData?.settings?.includes('denied-apps');
  const tableHeaders = [
    { title: 'App Name', onClick: toggleAppNameOrder, sortValue: appNameOrder },
    { title: 'App Type', onClick: () => {}, sortValue: null },
    { title: 'Date Submitted', onClick: toggleDateSubmittedOrder, sortValue: dateSubmittedOrder },
    { title: 'Devices', onClick: () => {}, sortValue: null },
    { title: 'Countries Contacted', onClick: () => {}, sortValue: null },
    {
      title: 'Policy Violations',
      onClick: () => {},
      sortValue: null,
    },
    { title: 'Actions', onClick: () => {}, sortValue: null },
  ];

  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>, applicationId: string, os: string) => {
    setPopoverId(applicationId);
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setPopoverAnchorEl(null);
  };

  const totalPageCount = Math.ceil(total / pageSize);
  const platformIcon = (os: string) => (os === 'ios' ? <StyledAppleIcon /> : <StyledAndroidIcon />);

  const renderAppTag = (isDenied, isAllowed, importantFindings) => {
    if (isDenied) {
      return (
        <span>
          <StyledDeniedIcon color="error" />
          <StyledWarningMessage>Denied App</StyledWarningMessage>
        </span>
      );
    }
    if (isAllowed) {
      return (
        <>
          <StyledTrustedIcon color="success" />
          <StyledTrustedTagText>Trusted App</StyledTrustedTagText>
        </>
      );
    }
    if (importantFindings) {
      return (
        <span>
          <StyledWarningIcon color="error" />
          <StyledWarningMessage>Important Findings</StyledWarningMessage>
        </span>
      );
    }
    return null;
  };

  const appTypes = {
    MANAGED: 'Managed',
    DISCOVERED: 'Discovered',
  };

  if (appsDataError) {
    console.error(appsDataError);
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <div>
        <KwSearchInput
          value={searchQuery}
          onChange={(newValue: string) => {
            setSearchQuery(newValue);
            setPage(0);
          }}
          placeholder="Search applications"
        />
        <KwTablePaginationWrapper>
          <KwRowsPerPage
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            page={page}
            totalNumberOfEntries={total}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
        </KwTablePaginationWrapper>
        <KwTable aria-label="Applications-Table">
          <KwTableHead>
            <KwTableRow>
              {tableHeaders.map(({ title, onClick, sortValue }) => (
                <KwColumnSortHeader
                  key={title}
                  title={title}
                  onClick={() => {
                    onClick();
                    setPage(0);
                  }}
                  sortValue={sortValue}
                />
              ))}
              <th></th>
            </KwTableRow>
          </KwTableHead>
          <KwTableBody>
            {apps?.map(
              ({
                applicationId,
                applicationVersion,
                // @ts-ignore
                analyzedVersion,
                contactedCountryCodes,
                deviceCount,
                // @ts-ignore
                appIconBase64,
                importantFindings,
                management,
                os,
                policyViolations,
                policyIds,
                riskIndicatorCount,
                title,
                updatedDate,
                // @ts-ignore
                isDenied,
                // @ts-ignore
                isAllowed,
                // @ts-ignore
                installedVersion,
                // @ts-ignore
                analysisId,
                // @ts-ignore
                dateSubmitted,
                // @ts-ignore
                status,
              }) => {
                const localIconUrl = `data:image/png;base64,${appIconBase64}`;
                const encodedTitle = encodeURIComponent(title);
                // const totalDeviceCount = sumDeviceCounts(versions);

                const reportPath = flags?.appDetailReportView
                  ? `/applications/app-detail-report?appId=${applicationId}&appName=${encodedTitle}&appVersion=${applicationVersion}&os=${os}&mastId=`
                  : `/applications/application-details?appId=${applicationId}&appName=${encodedTitle}&appVersion=${applicationVersion}&os=${os}`;

                return (
                  <TableRowLink
                    ariaLabel={`${title} row`}
                    key={applicationId + installedVersion + os + riskIndicatorCount}
                    uniqueId={applicationId}
                    href={status === 'FINISHED' ? reportPath : null}
                  >
                    <KwTableCell selected={!!appNameOrder}>
                      <StyledAppContentWrapper>
                        {appIconBase64 ? (
                          <StyledIcon alt={`${applicationId} app icon`} src={localIconUrl} />
                        ) : (
                          <StyledIconPlaceholder alt={`${applicationId} placeholder app icon`} src="/icons/app-placeholder-60x60.svg" />
                        )}
                        <ConditionalLink reportPath={status === 'FINISHED' ? reportPath : null}>
                          <StyledAppContent>
                            <div>
                              <StyledAppTitle>{decodeURIComponent(title)}</StyledAppTitle>
                              {renderAppTag(isDenied, isAllowed, importantFindings)}
                            </div>
                            <div>{applicationId}</div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <StyledIconWrapper>{platformIcon(os)}</StyledIconWrapper>
                              {/* Managed Apps may have installed version 0, then display the analyzed version instead. No substitution icon for such managed apps */}
                              {installedVersion === '0' ? analyzedVersion : installedVersion}
                              {installedVersion !== '0' && (
                                <SubstituteVersion analyzedVersion={analyzedVersion} installedVersion={installedVersion} />
                              )}
                            </div>
                          </StyledAppContent>
                        </ConditionalLink>
                      </StyledAppContentWrapper>
                    </KwTableCell>
                    <KwTableCell>{appTypes[management] || ''}</KwTableCell>
                    <KwTableCell>
                      <div>
                        <StyledIconWrapper>
                          <StyledCalendarIcon />
                        </StyledIconWrapper>
                        <StyledUpdatedDate>{dayjs.utc(dateSubmitted).local().format('MM/DD/YY')}</StyledUpdatedDate>
                      </div>
                    </KwTableCell>
                    <KwTableCell>
                      <div>{deviceCount}</div>
                    </KwTableCell>

                    <CountryCodesAndPolicyViolationsCells
                      status={status}
                      policyIds={policyIds}
                      contactedCountryCodes={contactedCountryCodes}
                      highRiskCountries={highRiskCountries}
                      highRiskCountriesError={highRiskCountriesError}
                    />
                    <KwTableCell>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          handlePopoverClick(e, applicationId, os);
                        }}
                      >
                        <StyledMoreVertIcon />
                      </IconButton>
                    </KwTableCell>
                    <KwTableCell>
                      <ApplicationsPopover
                        deniedApps={deniedApps}
                        trustedApps={trustedApps}
                        applicationId={applicationId}
                        applicationVersion={applicationVersion}
                        os={os}
                        name={title}
                        isOpen={popoverId === applicationId}
                        anchorEl={popoverAnchorEl}
                        handleClose={handlePopoverClose}
                        fetchDeniedApps={fetchDeniedApps}
                        fetchTrustedApps={fetchTrustedApps}
                        showDeniedApps={showDeniedApps}
                        disableOptions={status !== 'FINISHED'}
                      />
                    </KwTableCell>
                  </TableRowLink>
                );
              },
            )}
          </KwTableBody>
        </KwTable>
        {total > 10 ? (
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
          </KwTablePaginationWrapper>
        ) : null}
      </div>
    </>
  );
}

const ConditionalLink = ({ children, reportPath }: { children: React.ReactNode; reportPath: string }) => {
  return reportPath ? (
    <StyledLink to={reportPath} onClick={e => e.stopPropagation()}>
      {children}
    </StyledLink>
  ) : (
    <>{children}</>
  );
};

// This function maps the status to a message and color
const mapStateToMessage = status => {
  switch (status) {
    case 'NOT_STARTED':
      return ['Not started', 'var(--Text-Color-446C, #3B3E40)'];
    case 'FAILED':
      return ['Analysis unavailable', 'var(--Text-Color-Danger, #DF2E21)'];
    default:
      return ['Analysis in progress, this may take a while...', 'var(--Text-Color-446C, #3B3E40)'];
  }
};

const CountryCodesAndPolicyViolationsCells = ({ status, policyIds, contactedCountryCodes, highRiskCountries, highRiskCountriesError }) => {
  const [message, color] = mapStateToMessage(status);

  return status === 'FINISHED' ? (
    <>
      <StyledCountriesTableCell>
        <CountriesContactedCellContent
          contactedCountryCodes={contactedCountryCodes}
          highRiskCountries={highRiskCountries}
          highRiskCountriesError={highRiskCountriesError}
        />
      </StyledCountriesTableCell>
      <KwTableCell>
        {policyIds.length > 0 ? (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <Typography variant="bodyRegular" color={'var(--Text-Color-Danger, #DF2E21)'}>
              {policyIds.length}
            </Typography>
            <WarningIcon sx={{ width: '16px', height: '16px', translate: '0px -1px' }} color="error" />
          </div>
        ) : (
          '0'
        )}
      </KwTableCell>
    </>
  ) : (
    <KwTableCell colSpan={2}>
      <Typography variant="bodyRegular" color={color}>
        {message}
      </Typography>
    </KwTableCell>
  );
};

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledAppContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 20px 10px 0;
`;

const StyledCountriesTableCell = styled(KwTableCell)`
  min-width: 200px;
`;

const AppIconStyles = css`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  display: block;
`;

const StyledIcon = styled(Avatar)`
  ${AppIconStyles}
`;

const StyledIconPlaceholder = styled('img')`
  ${AppIconStyles}
`;

const StyledWarningIcon = styled(WarningIcon)`
  font-size: 21px;
  margin: -6px 4px;
`;

const StyledDeniedIcon = styled(DeniedIcon)`
  font-size: 21px;
  margin: -6px 4px;
`;

const StyledTrustedIcon = styled(TrustedIcon)`
  font-size: 21px;
  margin: -6px 4px;
`;

const StyledTrustedTagText = styled('span')`
  color: #4caf50;
`;

const StyledWarningMessage = styled('span')`
  color: #d32f2f;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledAppContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledIconWrapper = styled('div')`
  display: inline-block;
  margin-right: 5px;
`;

const StyledAppleIcon = styled(Apple)`
  font-size: 12px;
  color: #a2aaad;
`;

const StyledAndroidIcon = styled(Android)`
  font-size: 12px;
  color: #a4c639;
`;

const StyledCalendarIcon = styled(CalendarToday)`
  font-size: 12px;
  color: #244c5a;
  vertical-align: middle;
`;

const StyledUpdatedDate = styled('div')`
  display: inline-block;
  vertical-align: middle;
`;

const StyledAppTitle = styled('div')`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  display: inline-block;
`;

const StyledMoreVersions = styled('span')`
  font-weight: 700;
  color: #244c5a;
  margin-left: 5px;
`;

const iconStyles = props => css`
  color: ${props.theme.palette.accent.primary};
`;

const StyledMoreVertIcon = styled(MoreVertIcon)`
  ${iconStyles}
`;
