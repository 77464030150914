import { Box } from '@mui/material';
import { AsyncTablePagination, QCard, QCardContent, QTabContext, QTabList, QTab, QTabPanel, QTable, QTableBody, QTableRow, QTableCell } from '@kw/quokka-ui';
import { useEffect, useState } from 'react';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { countriesMap } from '../../../../utils/countriesMap';
import { useHighRiskCountries } from '../../hooks';
import { NetworkInfoMap } from '../NetworkInfoMap';
import CountryFlag from 'react-country-flag';
import { styled } from '@mui/material/styles';

export interface CountryMapInfo {
  countryCode: string;
  count: number;
  countryName: string;
  latitude: number;
  longitude: number;
  domains: string[];
  isHighRisk?: boolean;
}

export const NetworkInfoTab = ({ appDetails }) => {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [activeTab, setActiveTab] = useState('all domains');
  const [countryData, setCountryData] = useState<{ [key: string]: CountryMapInfo }>({});
  const [domains, setDomains] = useState<{ country: string; domain: string}[]>([]);
  const [loading, setLoading] = useState(false);
  const handleTabChange = (event, newTabValue) => {
    setActiveTab(newTabValue);
  };

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];
  const [paginationOptions, setPaginationOptions] = useState({
    total: 0,
    lastPage: 0,
    perPage: 0,
  });

  const handleChangePage = (newPage, event) => {
    event.preventDefault();
    setPage(newPage);
  };

  // Handle page size change
  const handlePageSizeChange = option => {
    setPageSize(option);
    setPage(1);
  };

  useEffect(() => {
    // format map data
    setLoading(true);
    const mapData: { [key: string]: CountryMapInfo } = {};
    const domains: { country: string; domain: string }[] = [];
    appDetails?.current?.appDetails?.domainsContacted.map(({ domain, country }) => {
      if (country === '') return;
      // aggregate unique domains and countries
      if (!domains.includes(domain)) {
        domains.push({ country, domain });
      }
      // format map data
      if (!mapData[country]) {
        const countryInfo = {
          countryCode: country,
          count: 1,
          countryName: countriesMap[country].countryName,
          latitude: countriesMap[country].latitude,
          longitude: countriesMap[country].longitude,
          domains: [domain],
        }
        mapData[country] = countryInfo;
      }
      if (!mapData[country]['domains'].includes(domain)) {
        mapData[country]['count'] += 1;
        mapData[country]['domains'].push(domain);
      }
    });
    setCountryData(mapData);
    setDomains(domains);
    setPaginationOptions({
      total: domains.length,
      lastPage: Math.ceil(domains.length / pageSize),
      perPage: pageSize,
    });
    setLoading(false);
  }, [appDetails]);

  const { highRiskCountries, highRiskCountriesError } = useHighRiskCountries(accessToken);
  const isLoading = !countryData && !highRiskCountries && !highRiskCountriesError;
  return (
    <Box>
      <Box>
        <QCard>
          <QCardContent>
            <NetworkInfoMap
              data={countryData}
              error={false}
              highRiskCountries={highRiskCountries}
              highRiskCountriesError={highRiskCountriesError}
              isLoading={isLoading}
            />
          </QCardContent>
        </QCard>
      </Box>
      <QTabContext value={activeTab}>
        <QTabList
          onChange={handleTabChange}
          sx={{
            '.MuiTabs-indicator': {
              top: 0, // Moves the indicator to the top
              bottom: 'unset', // Removes default bottom positioning
            },
            '.MuiTab-root': {
              paddingTop: '16px',
              fontSize: '14px',
              fontWeight: '600',
              color: '#757575',
              minWidth: '226px',
            },
            '.Mui-selected': {
              backgroundColor: '#FAFAFA',
              color: '#000000',
              fontWeight: '800',
            },
            backgroundColor: '#EEEEEE',
            borderBottom: 'none',
            marginTop: '40px',
          }}
        >
          <QTab label="All Domains" value="all domains" />
          <QTab label="Social Networks" value="social networks" />
          <QTab label="Ad Networks" value="ad networks" />
        </QTabList>
        <QTabPanel value="all domains" sx={{ padding: 0 }}>
          {loading ? <Box>Loading...</Box> : (
            <>
              <QTable>
                <QTableBody>
                  {paginationOptions.total > 0 && domains?.slice((page - 1) * pageSize, page * pageSize).map(({ domain, country }, index) => (
                    <QTableRow key={`${domain}-${country}-icon`} hoverDisabled>
                      <QTableCell>
                        <StyledCountryFlag countryCode={country} svg />
                        {domain}
                      </QTableCell>
                    </QTableRow>
                  ))}
                </QTableBody>
              </QTable>
              {paginationOptions.total > pageSizeOptions[0].value && (
                <AsyncTablePagination
                  pagination={{
                    lastPage: paginationOptions.lastPage,
                    totalData: paginationOptions.total,
                    dataPerPage: pageSize,
                  }}
                  getPage={handleChangePage}
                  page={page}
                  showRowsPerPage
                  currentRows={domains.slice((page - 1) * pageSize, page * pageSize).length} 
                  handlePageSizeChange={handlePageSizeChange}
                  pageSize={pageSize}
                  pageSizeOptions={pageSizeOptions}
                />
              )}
            </>
          )}
        </QTabPanel>
        <QTabPanel value="social networks" sx={{ padding: 0 }}>
          <QTable>
            <QTableBody>
              {appDetails?.current?.appDetails?.socialNetworks.map((network: string) => (
                <QTableRow key={network} hoverDisabled>
                  <QTableCell>
                    <img src={`/icons/${network.toLowerCase()}.png`} alt={network} height='24' width='24' style={{ margin: '8px 8px -8px 0' }} />
                    {network}
                  </QTableCell>
                </QTableRow>
              ))}
            </QTableBody>
          </QTable>
        </QTabPanel>
        <QTabPanel value="ad networks" sx={{ padding: 0 }}>
          <QTable>
            <QTableBody>
              {appDetails?.current?.appDetails?.adNetworks.map((network: string) => (
                <QTableRow key={network} hoverDisabled><QTableCell>{network}</QTableCell></QTableRow>
              ))}
            </QTableBody>
          </QTable>
        </QTabPanel>
      </QTabContext>
    </Box>
  );
};

const StyledCountryFlag = styled(CountryFlag)`
  width: 24px;
  height: 16px;
  margin-right: 8px;
`;
