import { IconButton, styled } from '@mui/material';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { PageLoader } from '../../../PageLoader';
import { MapScrollWheelZoomNotifier, scrollInstructions } from '../../shared/MapScrollWheelZoomNotifier';
import '../css/map.css';
import '../../shared/css/mapScrollWheelZoomNotifier.css';
import { CountryMapInfo } from './tabs/NetworkInfoTab';
import { useState } from 'react';
import CountryFlag from 'react-country-flag';
import { QCard, QCardContent, QTable, QTableBody, QTableRow, QTableCell, QTypography } from '@kw/quokka-ui';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const maxMarkerSize = 60;

const DomainList = ({ countryName, domains, setShowDomainList }) => {
  const possessiveCountryName = countryName.endsWith('s') ? `${countryName}'` : `${countryName}'s`;
  return (
    <>
      <QTypography sx={{ fontWeight: 700 }}>
        <IconButton onClick={() => setShowDomainList(false)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        {possessiveCountryName} domain list
      </QTypography>
      <QTable>
        <QTableBody>
          {domains.map((domain: string) => (
            <QTableRow key={domain} hoverDisabled>
              <QTableCell>{domain}</QTableCell>
            </QTableRow>
          ))}
        </QTableBody>
      </QTable>
    </>
  );
};

const CountriesContactedOverlay = ({ showDomainList, setShowDomainList, domainListData, setDomainListData, countries }) => {
  return (
    <QCard className="overlay" sx={{ zIndex: 1000, overflow: 'scroll' }}>
      <QCardContent sx={{ padding: '8px 16px'}}>
        {showDomainList ? (
          <DomainList countryName={domainListData.countryName} domains={domainListData.domains} setShowDomainList={setShowDomainList} />
        ) : (
          <>
            <QTypography sx={{ fontWeight: 700, padding: '8px' }}>Countries Contacted</QTypography>
            <QTable>
              <QTableBody>
                {countries.map(({ countryName, countryCode, domains, isHighRisk }, index) => {
                  return (
                    <QTableRow key={`${countryName}-${index}`} sx={{ cursor: 'pointer' }}>
                      <QTableCell onClick={() => {
                        setShowDomainList(true);
                        setDomainListData({ countryName, domains });
                      }}>
                        <StyledCountryFlag countryCode={countryCode} svg />
                        {isHighRisk ? <span className="overlay-high-risk-country-name">{countryName}</span> : <span>{countryName}</span>}
                      </QTableCell>
                    </QTableRow>
                  );
                })}
              </QTableBody>
            </QTable>
          </>
        )}
      </QCardContent>
    </QCard>
  )
}

export function NetworkInfoMap({
  data = {},
  error,
  highRiskCountries,
  highRiskCountriesError,
  isLoading,
}: {
  data: { [key: string]: CountryMapInfo };
  error: boolean;
  highRiskCountries: string[];
  highRiskCountriesError: boolean;
  isLoading: boolean;
}) {
  const [showDomainList, setShowDomainList] = useState(false);
  const [domainListData, setDomainListData] = useState<{ countryName: string; domains: string[] }>({ countryName: '', domains: [] });

  const filteredCountries = Object.values(data).map((country: CountryMapInfo) => {
    return {
      ...country,
      isHighRisk: highRiskCountries?.includes(country.countryCode),
    };
  });

  const appsMaxCount = Math.max(...(filteredCountries as unknown as { count: number }[]).map(country => country.count));
  
  // prevents scrolling on overlay from triggering map zoom scroll notifier
  const overlay = document?.getElementsByClassName('overlay')[0];
  overlay?.addEventListener('wheel', e => e.stopPropagation());


const MarkerWithToolTip = ({
  marker,
  className,
}: {
  marker: {
    latitude: number;
    longitude: number;
    count: number;
    maxCount: number;
    countryName: string;
    countryCode: string;
    isHighRisk: boolean;
    domains: string[];
  };
  className: 'lowRiskCustomMarker' | 'highRiskCustomMarker';
}) => {
  const { countryName, countryCode, count, maxCount, latitude, longitude, isHighRisk, domains } = marker;
  const iconSize = Math.max(30, (count * maxMarkerSize) / maxCount);
  const icon = L.divIcon({
    className,
    html: `<div><span>${count}</span></div>`,
    iconSize: [iconSize, iconSize],
  });

  const handleMarkerClick = () => {
    if (!showDomainList) {
      setShowDomainList(true);
    }
    setDomainListData({ countryName, domains });
  };

  return (
    <Marker icon={icon} position={[latitude, longitude]} key={JSON.stringify([latitude, longitude])} eventHandlers={{
      click: handleMarkerClick,
    }}>
      <Tooltip direction="top">
        <div>
          <StyledCountryFlag countryCode={countryCode} svg />
          <span className={isHighRisk ? 'tooltip-high-risk-country-name tooltip-country-name' : 'tooltip-country-name'}>{countryName}</span>
        </div>
        <div className="tooltip-domain">Domains<div className="tooltip-domain-count">{count}</div></div>
      </Tooltip>
    </Marker>
  );
};

  if (error || highRiskCountriesError) {
    console.error(error);
    return <div>failed to load</div>;
  }

  return (
    <QCardContent>
      {isLoading && <PageLoader />}
      <StyledMapContainer
        id="map"
        center={[39.8397, 24.0297]}
        zoom={2}
        zoomControl={true}
        maxZoom={6}
        scrollWheelZoom={false}
        whenReady={() => {
          const mapElement = document.getElementById('map');
          mapElement.setAttribute('data-scroll-instructions', scrollInstructions);
        }}
      >
        <MapScrollWheelZoomNotifier />
        <CountriesContactedOverlay
          showDomainList={showDomainList}
          setShowDomainList={setShowDomainList}
          domainListData={domainListData}
          setDomainListData={setDomainListData}
          countries={filteredCountries}
        />
        <TileLayer
          url={`https://api.maptiler.com/maps/bright-v2/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
          attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          tileSize={512}
          zoomOffset={-1}
        />
        {filteredCountries.map(({ countryCode, countryName, count, latitude, longitude, domains, isHighRisk }) => {
          return (
            <MarkerWithToolTip
              marker={{ countryName, countryCode, count, maxCount: appsMaxCount, latitude, longitude, isHighRisk, domains }}
              className={isHighRisk ? 'highRiskCustomMarker' : 'lowRiskCustomMarker'}
              key={`map-marker-${countryName}`}
            />
          );
        })}
      </StyledMapContainer>
    </QCardContent>
  );
}

const StyledMapContainer = styled(MapContainer)`
  height: 500px;
  width: 100%;
`;

const StyledCountryFlag = styled(CountryFlag)`
  width: 24px;
  height: 16px;
  margin-right: 8px;
`;
