import { useMemo, useState } from 'react';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { QSearchBar, QTable, QTableBody, QTableRow, QTableCell, RulesTable } from '@kw/quokka-ui';
import _ from 'lodash';
import { fetchService } from '../../../../utils/fetchService';
import { Box } from '@mui/material';
import { Filters } from './../Filters';
import { generateFilterOptions, getInitialFilters, filterByQuery as filterBySearchQuery, getRulesFiltersConfig } from '../utils/filterHelper';

export function BehaviorsTab({ ruleSummaries, mastId}) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredByDropDown, setFilteredByDropDown] = useState(ruleSummaries);
  const [filteredBySearchQuery, setFilteredBySearchQuery] = useState(ruleSummaries);

  const filtersConfig = getRulesFiltersConfig(ruleSummaries, ['category']);
  const initialFilters = useMemo(() => getInitialFilters(filtersConfig), [filtersConfig]);
  const filterOptions = generateFilterOptions(filtersConfig, ruleSummaries);

  const getEvidenceCallback = async (mastId: string, ruleGuid: string, page = 1, pageSize = 10) => {
    const data = await fetchService(`/application/results/${mastId}/evidence?page=${page}&ruleGuid=${ruleGuid}&pageSize=${pageSize}`, accessToken);
    return { data };
  };

  const handleFilteredData = filteredData => {
    setFilteredByDropDown(filteredData);
    const newFilteredData = filterBySearchQuery(filteredData, searchQuery);
    setFilteredBySearchQuery(newFilteredData);
  };

  const handleSearchQuery = e => {
    const query = e.target.value.toString();
    setSearchQuery(query);
  };

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      const value = e.target.value.toString();
      setSearchQuery(value);
      const filtered = filterBySearchQuery(filteredByDropDown, value);
      setFilteredBySearchQuery(filtered);
    }
  };

  const resetQuery = () => {
    setSearchQuery('');
    setFilteredBySearchQuery(filteredByDropDown);
  };

  const behavioralRulesTable = useMemo(() => {
    return <RulesTable ruleSummaries={filteredBySearchQuery} uuid={mastId} getEvidencesCallback={getEvidenceCallback} />
  }, [filteredBySearchQuery, mastId, accessToken]);

  return (
    <>
    <QTable data-testid="behavioral-rule-summaries-table">
      <QTableBody>
        <QTableRow hoverDisabled>
          <QTableCell sx={{ padding: '0px' }}>
            <Box
              sx={{
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '8px',
                alignSelf: 'stretch',
              }}
              >
            <Filters
              initialFilters={initialFilters}
              filtersConfig={filtersConfig}
              options={filterOptions}
              onChange={handleFilteredData}
              data={ruleSummaries}
            />
            <QSearchBar 
              searchValue={searchQuery}
              onChange={handleSearchQuery}
              onRemove={resetQuery}
              onKeyPress={e => handleKeyPress(e)}
              placeholder="Search"
            />
            </Box>
          </QTableCell>
        </QTableRow>
      </QTableBody>
      </QTable>
     {behavioralRulesTable}
    </>
  );
}
