import type { DeviceState } from '@kw/device-service/dist/device/entities/deviceState.entity';
import type { Indicator as RiskIndicator } from '@kw/service-definitions-risk_score';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { JSXElementConstructor, useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GetDeviceStatePolicyStatusResponse } from '@kw/service-definitions-rule';
import { Avatar } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { useLocation } from 'react-router-dom';
import { useApplicationSortParams } from '../Applications/useApplicationSortParams';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { RiskLevelPane } from './RiskLevelPane';
import { useInstalledApps } from './useInstalledApps';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { Link } from '../../Link';
import { PageLoader } from '../../PageLoader';
import { getMostRecentState } from '../../utils/getMostRecentState';
import { getOldestState } from '../../utils/getOldestState';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { fetchService, useFetchService } from '../../utils/fetchService';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { createQueryString } from '../../utils/createQueryString';
import { getTriggeredPoliciesListString } from '../../utils/getTriggeredPoliciesListString';
import { LaunchDarklyFlags } from '../../launch-darkly';
import { TableRowLink } from '../shared/TableRowLink';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';
import { NavigationContext } from '../Navigation/NavigationProvider';

export default function DeviceDetailsPage() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [appIcons = {}, updateAppIcons] = useLocalStorage('appIcons', {});
  const [currentDeviceStateId, setCurrentDeviceStateId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const flags: LaunchDarklyFlags['flags'] = useFlags();
  const location = useLocation();
  const { deviceId } = Object.fromEntries(new URLSearchParams(location.search));

  const { deviceDetails, setDeviceDetails } = useContext(NavigationContext);

  const {
    appNameOrder,
    toggleAppNameOrder,
    appIdOrder,
    toggleAppIdOrder,
    developerOrder,
    numberOfIorsOrder,
    toggleNumberOfIorsOrder,
    securityRiskLevelOrder,
    privacyRiskLevelOrder,
  } = useApplicationSortParams();

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const { data: device, error: deviceDataError } = useFetchService(`/device/${deviceId}`, accessToken);

  const queryParamString = createQueryString({ deviceStateId: currentDeviceStateId });
  const shouldFetch = Boolean(currentDeviceStateId);
  let deviceStatePolicyStatusPath = `/rule/policies/device-state-policy-status?${queryParamString}`;

  if (flags?.evalService) {
    deviceStatePolicyStatusPath = deviceStatePolicyStatusPath.replace('/rule/', '/eval/');
  }

  const { data: response, error: policyStatusError } = useFetchService<GetDeviceStatePolicyStatusResponse>(
    deviceStatePolicyStatusPath,
    accessToken,
    shouldFetch,
  );

  const triggeredPoliciesListString = getTriggeredPoliciesListString(response);

  let deviceSerial;
  if (device) {
    deviceSerial = device.serial;
  }

  useEffect(() => {
    /* 
      HACK - to get correct default sort order (Issues column DESC)
      in dev environment, using react strict mode, components re-render an extra time which executes toggle twice
      in production, components only render once so we need to execute toggle twice
     */
    toggleNumberOfIorsOrder();
    if (process.env.NODE_ENV !== 'development') {
      toggleNumberOfIorsOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canListApps: boolean = verifyAuthPermission(AuthPermissions.LIST_DEVICE_APPS);
  const { apps, appDataError, total } = useInstalledApps(
    canListApps,
    {
      page: String(page),
      pageSize: String(pageSize),
      query: debouncedSearchQuery,
      deviceId,
      appNameOrder,
      appIdOrder,
      developerOrder,
      numberOfIorsOrder,
      securityRiskLevelOrder,
      privacyRiskLevelOrder,
    },
    accessToken,
  );

  const { apps: topSecurityScoreApps, appDataError: topSecurityScoreAppsError } = useInstalledApps(
    canListApps,
    {
      pageSize: 10,
      page: 0,
      deviceId,
      securityRiskLevelOrder: 'DESC',
    },
    accessToken,
  );

  const { apps: topPrivacyScoreApps, appDataError: topPrivacyScoreAppsError } = useInstalledApps(
    canListApps,
    {
      pageSize: 10,
      page: 0,
      deviceId,
      privacyRiskLevelOrder: 'DESC',
    },
    accessToken,
  );

  let latestDeviceState;

  if (device) {
    latestDeviceState = getMostRecentState<DeviceState>(device.states);
  }

  useEffect(() => {
    if (latestDeviceState) {
      setCurrentDeviceStateId(latestDeviceState.id);
    }
  }, [latestDeviceState]);

  useEffect(() => {
    if (device) {
      setDeviceDetails({ deviceName: device.name, deviceId: device.id });
    }
  }, [device, setDeviceDetails]);

  useEffect(() => {
    const fetchAppMetaData = async () => {
      try {
        if (apps?.length) {
          for (const app of apps) {
            const appIconKey = `${app.applicationId}-${app.os}`;

            if (appIcons[appIconKey]) continue;

            const queryParams = createQueryString({
              packageName: app.applicationId,
              platform: app.os,
              fields: 'iconLargeUrl',
              maxAge: '86400',
            });

            const res = await fetchService(`/application/app-metadata?${queryParams}`, accessToken);

            if (res?.iconLargeUrl && !appIcons[appIconKey]) {
              app.icon = res.iconLargeUrl;
              updateAppIcons((icons: { [key: string]: string }) => ({ ...icons, [appIconKey]: app.icon }));
              break;
            }
          }
        }
      } catch (error) {
        console.log('Error fetching app icon', error);
      }
    };

    fetchAppMetaData();
  }, [accessToken, appIcons, apps, updateAppIcons]);

  if (appDataError || deviceDataError || topSecurityScoreAppsError || topPrivacyScoreAppsError) {
    if (appDataError) console.error(appDataError);
    if (deviceDataError) console.error(deviceDataError);
    if (topSecurityScoreAppsError) console.error(topSecurityScoreAppsError);
    if (topPrivacyScoreAppsError) console.error(topPrivacyScoreAppsError);

    return <div>failed to load</div>;
  }

  if (policyStatusError) {
    console.log('Unable to get policy status');
  }

  if (!device) {
    return <PageLoader />;
  }

  if (canListApps && (!apps || !device || !topSecurityScoreApps || !topPrivacyScoreApps)) {
    return <PageLoader />;
  }

  const oldestDeviceState = getOldestState<DeviceState>(device.states);
  const ownershipMap = {
    company: 'Company-Owned',
    personal: 'Personal-Owned',
  };

  const deviceMetaData = [
    ['OS Version', latestDeviceState.osVersion],
    ['IMEI', device.imei],
    ['Model', device.hardware.modelName],
    ['First Seen', dayjs(oldestDeviceState.createdDate).format('MM/DD/YYYY HH:mm:ss A')],
    ['OS Build Version', latestDeviceState.osBuildVersion],
    ['Serial Number', deviceSerial],
    ['Owner', ownershipMap[device.ownership]],
    ['Last Seen', dayjs(latestDeviceState.createdDate).format('MM/DD/YYYY HH:mm:ss A')],
  ];

  const tableHeaders = [
    { title: 'App Name', onClick: toggleAppNameOrder, sortValue: appNameOrder },
    { title: 'App ID', onClick: toggleAppIdOrder, sortValue: appIdOrder },
    { title: 'Version', onClick: () => {}, sortValue: null },
  ];
  const { riskIndicators } = latestDeviceState;

  const securityIorsCount = (riskIndicators as unknown as RiskIndicator[]).filter(ind => ind.isSecurityConcern).length;

  const privacyIorsCount = (riskIndicators as unknown as RiskIndicator[]).filter(ind => ind.isPrivacyConcern).length;

  const totalPageCount = Math.ceil(total / pageSize);

  const dedupeRiskLevelApps = (arr: { title: string }[]) => {
    const riskLevelApps = [...new Set(arr.map(obj => obj.title))].slice(0, 5);
    return riskLevelApps.map(
      title =>
        [title, device.os === 'android' ? <StyledAndroidIcon /> : <StyledAppleIcon />] as [
          string,
          React.ReactElement<any, string | JSXElementConstructor<any>>,
        ],
    );
  };

  return (
    <StyledKwContainer>
      <BackgroundProgressBar />
      <StyledBackButton href="/devices">
        <StyledArrowBack />
        <StyledBackText>Back to Device List</StyledBackText>
      </StyledBackButton>
      <StyledContent>
        <StyledMetaDataTable aria-label="device-meta-data-table">
          <tbody>
            <StyledMetaDataRow>
              {deviceMetaData.map(([title, dataValue]) => (
                <td key={title + dataValue}>
                  <StyledMetaDataTitle>{title}</StyledMetaDataTitle>
                  <StyledMetaDataText>{dataValue}</StyledMetaDataText>
                </td>
              ))}
            </StyledMetaDataRow>
          </tbody>
        </StyledMetaDataTable>

        <RiskLevelPane
          title="Security Risk Score"
          canListApps={canListApps}
          score={latestDeviceState.securityRiskLevel}
          numberOfIors={securityIorsCount}
          topRiskyApps={canListApps && dedupeRiskLevelApps(topSecurityScoreApps)}
        />

        <RiskLevelPane
          title="Privacy Risk Score"
          canListApps={canListApps}
          score={latestDeviceState.privacyRiskLevel}
          numberOfIors={privacyIorsCount}
          topRiskyApps={canListApps && dedupeRiskLevelApps(topPrivacyScoreApps)}
          triggeredPoliciesListString={triggeredPoliciesListString}
        />

        {canListApps && (
          <StyledTableWrapper>
            <StyledTableTitle>
              <Typography component="h2" variant="h4Medium">
                Apps on {deviceDetails.deviceName}
              </Typography>
            </StyledTableTitle>
            <StyledKwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder={`Search apps on ${deviceDetails.deviceName}`} />
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />

              {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
            </KwTablePaginationWrapper>

            <KwTable aria-label={`Installed Applications on ${deviceDetails.deviceName} Table`}>
              <KwTableHead>
                <KwTableRow>
                  {tableHeaders.map(({ title, sortValue, onClick }) => (
                    <KwColumnSortHeader
                      key={title}
                      title={title}
                      onClick={() => {
                        onClick();
                        setPage(0);
                      }}
                      sortValue={sortValue}
                    />
                  ))}
                </KwTableRow>
              </KwTableHead>
              <KwTableBody>
                {apps?.map(({ applicationId, applicationVersion, os, title, icon }) => {
                  const localIconUrl = appIcons[`${applicationId}-${os}`];
                  return (
                    <TableRowLink
                      ariaLabel={`${title} row`}
                      key={applicationId + applicationVersion + os}
                      uniqueId={applicationId}
                      href={`/applications/application-details?appId=${applicationId}&appName=${title}&appVersion=${applicationVersion}&os=${os}`}
                    >
                      <StyledKwTableCell selected={appNameOrder}>
                        {localIconUrl || icon ? (
                          <StyledIcon alt={`${applicationId} app icon`} src={localIconUrl || icon} />
                        ) : (
                          <StyledIconPlaceholder alt={`${applicationId} placeholder app icon`} src="/icons/app-placeholder-60x60.svg" />
                        )}
                        {decodeURIComponent(title)}
                      </StyledKwTableCell>
                      <KwTableCell selected={appIdOrder}>{applicationId}</KwTableCell>
                      <KwTableCell>{applicationVersion}</KwTableCell>
                    </TableRowLink>
                  );
                })}
              </KwTableBody>
            </KwTable>
            {total > 10 ? (
              <KwTablePaginationWrapper>
                <KwRowsPerPage
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pageSize={pageSize}
                  page={page}
                  totalNumberOfEntries={total}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
              </KwTablePaginationWrapper>
            ) : null}
          </StyledTableWrapper>
        )}
      </StyledContent>
    </StyledKwContainer>
  );
}
/** styles */

const StyledBackButton = styled(Link)`
  display: flex;
  height: 30px;
  padding-left: 18px;
  padding-right: 22px;
  column-gap: 11px;
  width: 177px;
  border-radius: 4px;
  margin-bottom: 7px;

  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.palette.greyOverride[800]};

  &:hover {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
`;

const StyledAppleIcon = styled(AppleIcon)`
  font-size: 32px;
  color: #a2aaad;
`;

const StyledAndroidIcon = styled(AndroidIcon)`
  font-size: 32px;
  color: #a4c639;
`;

const StyledBackText = styled('div')`
  font-size: 13px;
  line-height: 13px;
`;

const StyledArrowBack = styled(ArrowBackIcon)`
  width: 16px;
`;

const StyledTableTitle = styled('div')`
  height: 66px;
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;

const StyledKwContainer = styled(KwContainer)`
  padding: 8px 32px;
`;

const StyledContent = styled('div')`
  display: grid;
  grid-template-areas:
    'metadata' auto
    'security' auto
    'privacy.' auto
    'table' auto
    / 100%;

  grid-row-gap: 24px;
  margin-bottom: 35px;
`;

const StyledMetaDataTable = styled('table')`
  padding: 24px;
  width: 100%;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;

const StyledMetaDataRow = styled('tr')`
  display: grid;
  grid-template-columns: repeat(4, max(21%));
  row-gap: 24px;
`;

const StyledMetaDataTitle = styled('div')`
  font-size: 10px;
  line-height: 14px;
  color: '#818181';
`;

const StyledKwSearchInput = styled(KwSearchInput)`
  & div {
    border-radius: 0;
  }
`;

const StyledMetaDataText = styled('div')`
  font-size: 14px;
  line-height: 21px;
  color: #424242;
`;

const StyledTableWrapper = styled('div')`
  border-top: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledIcon = styled(Avatar)`
  margin-right: 10px;
`;

const StyledKwTableCell = styled(KwTableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIconPlaceholder = styled('img')`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const StyledWarningIcon = styled(WarningIcon)`
  margin: 0 0 0 5px;
`;
