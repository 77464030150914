import { RulesTable, QTable, QTableBody, QTableRow, QTableCell, QSearchBar } from '@kw/quokka-ui';
import { Box } from '@mui/material';
import { useState, useMemo } from 'react';
import { filterByQuery as filterBySearchQuery } from '../utils/filterHelper';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { fetchService } from '../../../../utils/fetchService';


export const LibrariesAndFrameworks = ({ ruleSummaries, mastId }) => {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBySearchQuery, setFilteredBySearchQuery] = useState(ruleSummaries);

  const getEvidenceCallback = async (mastId: string, ruleGuid: string, page = 1, pageSize = 10) => {
    const data = await fetchService(`/application/results/${mastId}/evidence?page=${page}&ruleGuid=${ruleGuid}&pageSize=${pageSize}`, accessToken);
    return { data };
  };

  const handleSearchQuery = e => {
    const query = e.target.value.toString();
    setSearchQuery(query);
  };

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      const value = e.target.value.toString();
      setSearchQuery(value);
      const filtered = filterBySearchQuery(ruleSummaries, value);
      setFilteredBySearchQuery(filtered);
    }
  };

  const resetQuery = () => {
    setSearchQuery('');
  };

  const librariesAndFrameworksTable = useMemo(() => {
    return <RulesTable ruleSummaries={filteredBySearchQuery} uuid={mastId} getEvidencesCallback={getEvidenceCallback} />
  }, [filteredBySearchQuery, mastId, accessToken]);
  
  return (
    <>
      <QTable data-testid="library-and-frameworks-rule-summaries-table">
        <QTableBody>
          <QTableRow hoverDisabled>
            <QTableCell sx={{ padding: '0px' }}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
                >
              <QSearchBar 
                searchValue={searchQuery}
                onChange={handleSearchQuery}
                onRemove={resetQuery}
                onKeyPress={e => handleKeyPress(e)}
                placeholder="Search"
              />
              </Box>
            </QTableCell>
          </QTableRow>
        </QTableBody>
        </QTable>
      {librariesAndFrameworksTable}
    </>
  );
}