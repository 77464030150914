import { RulesTable, QTable, QTableBody, QTableRow, QTableCell, QSearchBar } from '@kw/quokka-ui';
import { Box } from '@mui/material';
import { useState, useMemo } from 'react';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { fetchService } from '../../../../utils/fetchService';
import { Filters } from '../Filters';
import {
  generateFilterOptions,
  getInitialFilters,
  filterByQuery as filterBySearchQuery,
  getRulesFiltersConfig,
} from '../utils/filterHelper';

export const Permissions = ({ ruleSummaries, mastId }) => {
  const [accessToken = ''] = useLocalStorage('accessToken', '');

  const getEvidenceCallback = async (mastId: string, ruleGuid: string, page = 1, pageSize = 10) => {
    const data = await fetchService(
      `/application/results/${mastId}/evidence?page=${page}&ruleGuid=${ruleGuid}&pageSize=${pageSize}`,
      accessToken,
    );
    return { data };
  };

  const [filteredByDropDown, setFilteredByDropDown] = useState(ruleSummaries);
  const [filteredBySearchBox, setFilteredBySearchBox] = useState(ruleSummaries);
  const [query, setQuery] = useState('');

  const filtersConfig = getRulesFiltersConfig(ruleSummaries, ['category', 'compliance']);
  const filterOptions = generateFilterOptions(filtersConfig, ruleSummaries);

  // Handlers for Drop-Down filters updates. The filters filter the original data
  // then invokes this handler. We set apply two setState calls so that we speed up the rendering time.
  const handleFilteredDataFromSelects = filteredData => {
    setFilteredByDropDown(filteredData);
    const newFilteredData = filterBySearchQuery(filteredData, query);
    setFilteredBySearchBox(newFilteredData);
  };

  // Handlers for SearchBar updates. The search bar invokes the handler with the updated query.
  const handleSearchQuery = e => {
    const value = e.target.value.toString();
    setQuery(value);
  };

  const removeSearchQuery = () => {
    setQuery('');
    setFilteredBySearchBox(filteredByDropDown);
  };

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      const value = e.target.value.toString();
      setQuery(value);
      const newFilteredData = filterBySearchQuery(filteredByDropDown, value);
      setFilteredBySearchBox(newFilteredData);
    }
  };

  const PermissionsTable = useMemo(() => {
    return <RulesTable ruleSummaries={filteredBySearchBox} uuid={mastId} getEvidencesCallback={getEvidenceCallback} />;
  }, [filteredBySearchBox, mastId, accessToken]);

  return (
    <>
      <QTable data-testid="permissions-rule-summaries-table">
        <QTableBody>
          <QTableRow hoverDisabled>
            <QTableCell sx={{ padding: '0px' }}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Filters
                  initialFilters={getInitialFilters(filtersConfig)}
                  filtersConfig={filtersConfig}
                  options={filterOptions}
                  data={ruleSummaries}
                  onChange={handleFilteredDataFromSelects}
                />
                <QSearchBar
                  searchValue={query}
                  onChange={handleSearchQuery}
                  onRemove={removeSearchQuery}
                  onKeyPress={e => handleKeyPress(e)}
                  placeholder="Search"
                />
              </Box>
            </QTableCell>
          </QTableRow>
        </QTableBody>
      </QTable>
      {PermissionsTable}
    </>
  );
};
