import _ from 'lodash';

// Drop-Down filter Configurations for the tabs on the Guardian Report page
export const getRulesFiltersConfig = (ruleSummaries, excludeKeys = []) =>
  [
    {
      key: 'riskLevel',
      label: 'Risk Level',
      filteredKey: 'details.risk',
      originalData: ruleSummaries,
    },
    {
      key: 'category',
      label: 'Category',
      filteredKey: 'details.category',
      originalData: ruleSummaries,
    },
    {
      key: 'compliance',
      label: 'Compliance',
      filteredKey: 'rule.properties.compliance',
      customHandleFilterChange: (filteredKey, data, option) => {
        let optionValues;

        if (option.value === 'owasp') {
          optionValues = ['owasp-2016', 'owasp-2024'];
        } else {
          optionValues = option.value;
        }

        return filterByNestedProperty({
          data,
          filteredKey,
          optionValues,
          predicate: item => item.fail || item.kind === 'fail',
        });
      },
      customOptions: [
        { label: 'OWASP', value: 'owasp' },
        { label: 'NIAP', value: 'niap' },
        { label: 'GDPR', value: 'gdpr' },
      ],
      originalData: ruleSummaries,
    },
  ].filter(config => !excludeKeys.includes(config.key));

// Helper function to filter an array of objects
// based on a nested property path and provided values.
export const filterByNestedProperty = ({ data, filteredKey, optionValues, predicate }) =>
  data.filter(item => {
    const value = _.get(item, filteredKey);
    if (!value) return false;

    if (Array.isArray(optionValues)) {
      return optionValues.some(option => value[option]?.some(entry => predicate(entry)));
    }
    return value[optionValues]?.some(entry => predicate(entry));
  });

// Apply all selected filters to originalData
export const applyFilters = (filtersConfig, data, newSelectedFilters) => {
  let filteredData = [...data];

  filtersConfig.forEach(filter => {
    const { key, filteredKey, customHandleFilterChange } = filter;
    const selected = newSelectedFilters[key];

    if (selected.value === 'all') return; // Skip 'All' selections

    if (customHandleFilterChange) {
      filteredData = customHandleFilterChange(filteredKey, filteredData, selected);
    } else {
      filteredData = filteredData.filter(item => _.get(item, filteredKey) === selected.value);
    }
  });

  return filteredData;
};

export const getInitialFilters = filtersConfig =>
  filtersConfig.reduce((acc, filter) => {
    acc[filter.key] = { value: 'all' };
    return acc;
  }, {});

export const filterByQuery = (data, query) => {
  if (!query) {
    return data; // Return the original data if no query is provided
  }

  const lowerCaseQuery = query.toLowerCase();

  return data.filter(rule => {
    const description = rule?.rule?.shortDescription?.text;
    if (!description) {
      return false; // Skip items without a description
    }

    return description.toLowerCase().includes(lowerCaseQuery);
  });
};

export const generateOptions = (data, filter) => {
  const { filteredKey } = filter;
  const customOptions = filter.customOptions || [];

  if (customOptions.length) {
    return customOptions;
  }

  return _.uniqBy(
    data.map(item => {
      const value = _.get(item, filteredKey);
      const label = typeof value === 'string' ? _.capitalize(value) : 'Not specified';

      return {
        label,
        value,
      };
    }),
    'label'
  );
};

export const generateFilterOptions = (filtersConfig, ruleSummaries) => {
  return filtersConfig.reduce((options, filter) => {
    options[filter.key] = [{ label: 'All', value: 'all' }, ...generateOptions(ruleSummaries, filter)];
    return options;
  }, {});
};
