import { QTable, QTableBody, QTableHead, QTableRow, QTableCell, QThemeProvider } from '@kw/quokka-ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Indicator } from '@kw/service-definitions-risk_score';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { theme } from '../../theme';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useOrganization } from '../Settings/SettingsDropDownMenu';
import { RiskIndicatorInfoContext } from './RiskIndicatorInfoContext';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { sendHttpRequest } from '../../utils/network.service';
import { PageLoader } from '../../PageLoader';
import { useFetchService } from '../../utils/fetchService';
import { checkPermissions } from '../../auth/VerifyAuthPermission';
import { AuthPermissions } from '../../auth/AuthPermissions';
// eslint-disable-next-line import/extensions
import { RiskIndicatorProfileMode } from './enum/RiskIndicatorProfileMode.enum';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { useRiskIndicatorsSortParams } from './useRiskIndicatorsSortParams';
import { getComparator } from '../../utils/getComparator';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';

export const useRiskIndicators = (accessToken: string, organizationId: string) => {
  const {
    data: riskIndicatorsData,
    error: riskIndicatorsError,
    mutate,
  } = useFetchService(`/risk-score/indicators?organizationId=${organizationId}`, accessToken);

  const isLoading = !riskIndicatorsData && !riskIndicatorsError;

  return { riskIndicatorsData, riskIndicatorsError, isLoading, mutate };
};

export default function RiskIndicatorsTable() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const { setActiveRiskIndicator, setActiveOrganization, setMode } = useContext(RiskIndicatorInfoContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const hasDeletePermission = checkPermissions([AuthPermissions.DELETE_RISK_INDICATOR]);

  const hasCreateOrUpdatePermission = checkPermissions([AuthPermissions.CREATE_RISK_INDICATOR, AuthPermissions.UPDATE_RISK_INDICATOR]);

  const { orgData, organizationError, isLoading: isLoadingOrg } = useOrganization(accessToken);

  const {
    riskIndicatorsData: riskIndicators = [],
    riskIndicatorsError,
    isLoading: isLoadingIndicators,
    mutate,
  } = useRiskIndicators(accessToken, orgData.id);

  const filteredIndicators = useMemo(() => {
    return searchQuery.length
      ? riskIndicators.filter(
          (indicator: Indicator) =>
            indicator.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            indicator.adminTitle?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            indicator.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
            indicator.os.toLowerCase().includes(searchQuery.toLowerCase()) ||
            indicator.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            indicator.adminDescription?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            indicator.weight.toString().includes(searchQuery.toLowerCase()),
        )
      : riskIndicators;
  }, [riskIndicators, searchQuery]);

  const {
    indicatorNameOrder,
    toggleIndicatorNameOrder,
    keyOrder,
    toggleKeyOrder,
    platformOrder,
    togglePlatformOrder,
    weightOrder,
    toggleWeightOrder,
    modifiedOrder,
    toggleModifiedOrder,
    orderBy,
    setOrderBy,
  } = useRiskIndicatorsSortParams();

  const tableHeaders = [
    { title: 'Risk Indicator', onClick: toggleIndicatorNameOrder, sortValue: indicatorNameOrder },
    { title: 'Key', onClick: toggleKeyOrder, sortValue: keyOrder },
    { title: 'Platform', onClick: togglePlatformOrder, sortValue: platformOrder },
    { title: 'Weight', onClick: toggleWeightOrder, sortValue: weightOrder },
    { title: 'Modified', onClick: toggleModifiedOrder, sortValue: modifiedOrder },
  ];

  if (hasCreateOrUpdatePermission) {
    tableHeaders.push({ title: 'Actions', onClick: () => {}, sortValue: null });
  }

  // column sorting UI filters
  const order = tableHeaders.map(({ sortValue }) => sortValue).find(o => o);

  useEffect(() => {
    if (indicatorNameOrder) {
      setOrderBy('title');
    }

    if (keyOrder) {
      setOrderBy('key');
    }

    if (platformOrder) {
      setOrderBy('os');
    }

    if (weightOrder) {
      setOrderBy('weight');
    }

    if (modifiedOrder) {
      setOrderBy('isModified');
    }

    if (!order) {
      setOrderBy(null);
    }
  }, [indicatorNameOrder, weightOrder, modifiedOrder, order, orderBy, setOrderBy, keyOrder, platformOrder]);

  const deleteRiskIndicatorOverride = async (indicator: Indicator) => {
    await sendHttpRequest({
      path: `${process.env.RISK_SCORE_SERVICE_URL}/risk-score/organization-indicators/${indicator.id}`,
      method: 'DELETE',
      successMessage: `Successfully reset risk indicator override: ${indicator.title}`,
      errorMessage: `Error resetting risk indicator override: ${indicator.title}`,
      enqueueSnackbar,
    });
    mutate();
  };

  const handleEdit = async (indicator: Indicator) => {
    setActiveOrganization(orgData.id);
    if (indicator.isModified) {
      setMode(RiskIndicatorProfileMode.EDIT);
    } else {
      setMode(RiskIndicatorProfileMode.CREATE);
    }
    navigate(`/risk-indicators/edit/${indicator.id}`);
  };

  const isLoadingTableData = isLoadingIndicators || isLoadingOrg;

  if (organizationError || riskIndicatorsError) return <div>failed to load</div>;

  return (
    <>
      {isLoadingTableData && <PageLoader />}
      <ThemeProvider theme={theme}>
        <QThemeProvider>
          <KwContainer id="risk-indicators-table">
            <BackgroundProgressBar />
            <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search risk indicators" />
            <QTable aria-label="Risk Indicators Table">
              <QTableHead>
                <QTableRow>
                  {tableHeaders.map(({ title, onClick, sortValue }) => (
                    <KwColumnSortHeader key={title} title={title} onClick={onClick} sortValue={sortValue} />
                  ))}
                </QTableRow>
              </QTableHead>
              <QTableBody>
                {filteredIndicators.sort(getComparator(order, orderBy)).map((indicator: Indicator) => {
                  return (
                    <QTableRow key={indicator.id} aria-label={`${indicator.title} row`}>
                      <QTableCell>
                        <div>{indicator.adminTitle || indicator.title}</div>
                        <div>{indicator.adminDescription || indicator.description}</div>
                      </QTableCell>
                      <QTableCell>{indicator.key}</QTableCell>
                      <QTableCell>{indicator.os === 'android' ? 'Android' : 'iOS'}</QTableCell>
                      <QTableCell>{indicator.weight}</QTableCell>
                      <QTableCell>{String(indicator.isModified)}</QTableCell>
                      <StyledQTableCell>
                        <div>
                          {hasCreateOrUpdatePermission && (
                            <StyledKwButton
                              variant="filled"
                              onClick={() => {
                                setActiveRiskIndicator(indicator);
                                handleEdit(indicator);
                              }}
                            >
                              Edit
                            </StyledKwButton>
                          )}
                          {hasDeletePermission && indicator.isModified && (
                            <StyledKwButton onClick={() => deleteRiskIndicatorOverride(indicator)}>Reset</StyledKwButton>
                          )}
                        </div>
                      </StyledQTableCell>
                    </QTableRow>
                  );
                })}
              </QTableBody>
            </QTable>
          </KwContainer>
        </QThemeProvider>
      </ThemeProvider>
    </>
  );
}

const StyledKwButton = styled(KwButton)`
  margin-right: 10px;
`;

const StyledQTableCell = styled(QTableCell)`
  & > div {
    display: flex;
    gap: 10px;
  }
  min-width: 210px;
`;
