import { useState } from 'react';
import { HeaderAndMetadata } from '@kw/quokka-ui';
import _ from 'lodash';
import { MetadataPanel } from './MetadataPanel';

export function GuardianReportHeaderAndMetadata({ appName, appId, appVersion, os, iconUrl, appDetails, tag }) {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const { appDetails: applicationDetails, appAnalysis } = appDetails;

  return (
    <>
      <HeaderAndMetadata
        appName={appName}
        packageName={appId}
        platform={os}
        version={appVersion}
        appIconUrls={[iconUrl].filter(url => url)}
        viewMetadataOnClick={() => setIsPanelOpen(true)}
        substitutedVersion={applicationDetails?.analyzedVersion}
        submissionTag={`App Type - ${_.capitalize(appAnalysis?.management)}`}
        submissionDate={appAnalysis?.dateSubmitted}
        tag={tag}
      />
      <MetadataPanel isOpen={isPanelOpen} setIsOpen={setIsPanelOpen} appDetails={applicationDetails || {}} />
    </>
  );
}
