import { useCycleState } from '../../utils/useCycleState';

export const useApplicationSortParams = () => {
  const [appNameOrder, toggleAppNameOrder] = useCycleState([null, 'ASC', 'DESC']);

  const [appIdOrder, toggleAppIdOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [appOsOrder, toggleAppOsOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [appVersionOrder, toggleAppVersionOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [dateAnalyzedOrder, toggleDateAnalyzedOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [developerOrder, toggleDeveloperOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [numberOfDevicesOrder, toggleNumberOfDevicesOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [numberOfIorsOrder, toggleNumberOfIorsOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [dateSubmittedOrder, toggleDateSubmittedOrder] = useCycleState([null, 'ASC', 'DESC']);

  const [securityRiskLevelOrder, toggleSecurityRiskLevelOrder] = useCycleState([null, 'ASC', 'DESC']);

  const [privacyRiskLevelOrder, togglePrivacyRiskLevelOrder] = useCycleState([null, 'ASC', 'DESC']);

  const toggles = [
    toggleAppNameOrder,
    toggleAppIdOrder,
    toggleAppOsOrder,
    toggleAppVersionOrder,
    toggleDateAnalyzedOrder,
    toggleDeveloperOrder,
    toggleNumberOfDevicesOrder,
    toggleNumberOfIorsOrder,
    toggleSecurityRiskLevelOrder,
    togglePrivacyRiskLevelOrder,
    toggleDateSubmittedOrder,
  ];

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    appNameOrder,
    toggleAppNameOrder: toggleExclude(toggleAppNameOrder),
    appIdOrder,
    toggleAppIdOrder: toggleExclude(toggleAppIdOrder),
    appOsOrder,
    toggleAppOsOrder: toggleExclude(toggleAppOsOrder),
    appVersionOrder,
    toggleAppVersionOrder: toggleExclude(toggleAppVersionOrder),
    dateAnalyzedOrder,
    toggleDateAnalyzedOrder: toggleExclude(toggleDateAnalyzedOrder),
    developerOrder,
    toggleDeveloperOrder: toggleExclude(toggleDeveloperOrder),
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder: toggleExclude(toggleNumberOfDevicesOrder),
    numberOfIorsOrder,
    toggleNumberOfIorsOrder: toggleExclude(toggleNumberOfIorsOrder),
    securityRiskLevelOrder,
    toggleSecurityRiskLevelOrder: toggleExclude(toggleSecurityRiskLevelOrder),
    privacyRiskLevelOrder,
    togglePrivacyRiskLevelOrder: toggleExclude(togglePrivacyRiskLevelOrder),
    dateSubmittedOrder,
    toggleDateSubmittedOrder: toggleExclude(toggleDateSubmittedOrder),
    resetToggles,
  };
};
